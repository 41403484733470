import { makeStyles } from "@material-ui/core/styles";
import { themeStyleSheet, themeColors } from "../../../constants";
const useStyles = makeStyles((theme) => ({
  footerStyle: {
    borderTop: "1px solid " + themeColors.appColor,
    backgroundColor: "#FFF",
    fontSize: "20px",
    position: "fixed",
    left: 0,
    bottom: 0,
    height: "10vh",
    minHeight: "60px",
    width: "100%",
    zIndex:theme.zIndex.drawer -1,
  },
  phantomStyle: {
    display: "block",
    height: "10vh",
    minHeight: "60px",
    width: "100%",
  },
  tabStyle: {
    backgroundColor: "#fff",
    width: "100%",
    justifyContent: "space-between",
  },

  leftButton: {
    backgroundColor: "rgba(0,0,0,0.03)",
  },
  selected: {
    color: themeColors.appColor,
  },
  root: {
    color: "rgba(0,0,0,0.3)",
    "&$selected": {
      color: themeColors.appColor,
    },
  },
}));

export default useStyles;
