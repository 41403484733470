export const config = {
 
     
    apiKey: "AIzaSyCJovbvFyi1J7y04YO37TVfW6jsN1hQgUI",
    authDomain: "hisaab-7e8b4.firebaseapp.com",
    databaseURL: "https://hisaab-7e8b4.firebaseio.com",
    projectId: "hisaab-7e8b4",
    storageBucket: "hisaab-7e8b4.appspot.com",
    messagingSenderId: "170781208763",
    appId: "1:170781208763:web:0e2a98757ee1f59aeb399e",
    measurementId: "G-LN9T08NJG7"
  };
  

  export const  test_config = {
    apiKey: "AIzaSyCQu8dGWZ-vaaA9a9gWc79jgoGqWsSU6vs",
    authDomain: "test-ee754.firebaseapp.com",
    databaseURL: "https://test-ee754.firebaseio.com",
    projectId: "test-ee754",
    storageBucket: "test-ee754.appspot.com",
    messagingSenderId: "357391544072",
    appId: "1:357391544072:web:4fd66bdd6f91f5cc7c86bd",
    measurementId: "G-Q3L4BX78ZZ"
  };