import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Grid,
  TextField,
  Typography,
  Icon,
  IconButton,
  InputAdornment,
  CircularProgress,
  AppBar,
  Tabs,
  Tab,
  Paper,
  Box,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  TableFooter,
} from "@material-ui/core";
 
import jsPDF from 'jspdf';
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import useStyles from "./styles";

import Loader from "../../common/Loader";

import { formatNum, formattedDateWithTime, getHTMLForUserStatementPDF } from "../../../utils";
import AccountHeader from "../../common/AccountHeader";

import { strings } from "../../../i18n";

import { getLoggedInUser } from "../../../store/selectors";

import { themeColors } from "../../../constants";
import UserStatementMiddleware from "../../../store/middleware/UserStatement";
import DrawerHeader from "../../common/DrawerHeader";
const tabsDict = {
  0: "all",
  1: "credit",
  2: "debit",
};
export const UserStatement = ({ history }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const loggedInUser = useSelector(getLoggedInUser);
  const [selectedTab, setSelectedTab] = useState(0);

  const [loading, setLoading] = useState(false);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [statementData, setStatementData] = useState({});
  useEffect(() => {
    setLoading(true);
    dispatch(
      UserStatementMiddleware.getUserStatement(startDate, endDate)
    )
      .then((res) => {
        setLoading(false);
        setStatementData(res);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, [startDate, endDate]);

  const saveToPDF = () => {
    const {
      userTransactions,
      amount_of_total_credits,
      amount_of_total_debits,
      no_of_debits,
      no_of_credits,
      current_balance,
    } = statementData;
    let html = getHTMLForUserStatementPDF(loggedInUser, userTransactions, amount_of_total_credits, amount_of_total_debits, no_of_debits, no_of_credits, current_balance, startDate, endDate)

    var doc = new jsPDF({
      unit: "px"
    });


    let report_name = loggedInUser.business_name || loggedInUser.name
    doc.html(html, {
      callback: function () {
        doc.save(report_name + '_statement.pdf');
        window.open(doc.output('bloburl'));
      }
    });


  };

  const a11yProps = (index) => {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    };
  };
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };
  const TabPanel = (props) => {
    const { children, value, index } = props;

    const showDebit = tabsDict[value] === "debit" || tabsDict[value] === "all";
    const showCredit =
      tabsDict[value] === "credit" || tabsDict[value] === "all";
    const type = tabsDict[value];
    const {
      userTransactions,

      amount_of_total_credits,
      amount_of_total_debits,
      no_of_debits,
      no_of_credits,
      current_balance,
    } = statementData;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        style={{ width: "100%" }}
      >
        {value === index && (
          <Grid container item className={classes.abc}>
            <Grid container item className={classes.reportHeader}>
              {showCredit && (
                <Grid item xs={3} justify="center">
                  <Typography
                    align="center"
                    className={classes.noOfCrditTransactionText}
                  >
                    {no_of_credits}
                  </Typography>
                  <Typography align="center">{strings("CREDITS")}</Typography>
                </Grid>
              )}
              {showDebit && (
                <Grid item xs={3} justify="center">
                  <Typography
                    align="center"
                    className={classes.noOfCrditTransactionText}
                  >
                    {no_of_debits}
                  </Typography>
                  <Typography align="center">{strings("DEBITS")}</Typography>
                </Grid>
              )}
              <Grid
                item
                xs={6}
                justify="center"
                className={classes.balanceBox}
                style={{
                  color:
                    current_balance < 0
                      ? themeColors.amountDue
                      : themeColors.amountPay,
                }}
              >
                <Typography
                  align="center"
                  className={classes.noOfCrditTransactionText}
                >
                  PKR {formatNum(current_balance || 0)}
                </Typography>
                <Typography align="center">{strings("NET_BALANCE")}</Typography>
              </Grid>
            </Grid>

            <TableContainer
              container
              item
              className={classes.transactionTableCont}
              id="content-22"
            >
              <Table stickyHeader component={Paper}>
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{ width: "70%" }}
                      className={classes.tableHeaderText}
                    >
                      Transactions
                    </TableCell>
                    {showCredit && (
                      <TableCell className={classes.tableHeaderText}>
                           {strings("RECEIVE_PAYMENT")}
                      </TableCell>
                    )}
                    {showDebit && (
                      <TableCell className={classes.tableHeaderText}>
                        {strings("GIVE_PAYMENT")}
                      </TableCell>
                    )}
                  </TableRow>
                </TableHead>

                <TableBody className={classes.tableBody}>
                  {userTransactions?.map((item, index) => {
                    if (item.sub_type === "notification") return;
                    if (type !== "all" && item.transaction_type !== type)
                      return;
                    return (
                      <>
                        <TableRow>
                          <TableCell>
                            <Grid item style={{ width: "100%" }}>
                              <Typography className={classes.noteText}>
                                {item?.note}
                              </Typography>
                              <Typography className={classes.timestampText}>
                                {formattedDateWithTime(
                                  item.transaction_timestamp
                                )}
                              </Typography>
                            </Grid>
                          </TableCell>
                          {showCredit && (
                            <TableCell className={classes.creditText}>
                              {item.transaction_type === "credit"
                                ? formatNum(item.amount)
                                : ""}
                            </TableCell>
                          )}
                          {showDebit && (
                            <TableCell className={classes.debitText}>
                              {item.transaction_type === "debit"
                                ? formatNum(item.amount)
                                : ""}
                            </TableCell>
                          )}
                        </TableRow>
                      </>
                    );
                  })}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TableCell className={classes.stickyFooterCell}>
                      <Grid
                        container
                        style={{ width: "100%" }}
                        justify="space-between"
                      >
                        <Typography className={classes.totalText}>
                        {strings("TOTAL")}
                        </Typography>
                        <Typography className={classes.totalText}>
                          PKR
                        </Typography>
                      </Grid>
                    </TableCell>
                    {showCredit && (
                      <TableCell className={classes.stickyFooterCell}>
                        <Typography className={classes.totalAmountText}>
                          {formatNum(amount_of_total_credits || 0)}
                        </Typography>
                      </TableCell>
                    )}
                    {showDebit && (
                      <TableCell className={classes.stickyFooterCell}>
                        <Typography className={classes.totalAmountText}>
                          {formatNum(amount_of_total_debits || 0)}
                        </Typography>
                      </TableCell>
                    )}
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </Grid>
        )}
      </div>
    );
  };

  let endDateProps = startDate ? { "minDate": startDate } : {}
  return (
    <>
      <Grid direction="column" container xs={12} className={classes.mainCont}>
        <Loader loading={loading} />
        <AccountHeader
          title={strings("BUSINESS_STATEMENT")}
          leftIcon="arrow_back"
          leftIconFunc={() => history.goBack()}
          showAvatar={false}
          forceShowLeftIcon={true}
          rightIcon={"get_app"}
          rightIconFunc={saveToPDF}
        />

        <Grid container item justify="flex-start">
          <Grid container item>
            <Typography className={classes.dateRangeText}>
            {strings("DATE_RANGE")}
            </Typography>
          </Grid>

          <div className={classes.datePickerMainCont}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <DatePicker
                container="inline"
                inputProps={{
                  style: {
                    textAlign: "center",
                    color: "rgba(0,0,0,0.65)",
                    cursor: "pointer",
                    margin: "5px 2px",
                  },
                }}
                emptyLabel= {strings("START_DATE")}
                format="MMM DD, YYYY"
                value={startDate}
                maxDate={endDate || new Date()}
                onChange={(date) => setStartDate(date)}
                textFieldStyle={{ width: "100%" }}
                InputProps={{
                  disableUnderline: true,
                  startAdornment: (
                    <InputAdornment position={"end"}>
                      <Icon className={classes.datePickerCalenderIcon}>
                        event
                      </Icon>
                    </InputAdornment>
                  ),
                }}

              />
            </MuiPickersUtilsProvider>
          </div>

          <div className={classes.datePickerMainCont}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <DatePicker
                inputProps={{
                  style: {
                    textAlign: "center",
                    color: "rgba(0,0,0,0.65)",
                    cursor: "pointer",
                    margin: "5px 2px",
                  },
                }}
                emptyLabel={strings("END_DATE")}
                format="MMM DD, YYYY"
                value={endDate}
                onChange={(date) => setEndDate(date)}
                textFieldStyle={{ width: "100%" }}
                InputProps={{
                  disableUnderline: true,
                  startAdornment: (
                    <InputAdornment position={"end"}>
                      <Icon className={classes.datePickerCalenderIcon}>
                        event
                      </Icon>
                    </InputAdornment>
                  ),
                }}
                {...endDateProps}
                maxDate={new Date()}
              />
            </MuiPickersUtilsProvider>
          </div>
        </Grid>

        <Grid container item justify="center" className={classes.tabContainer}>
          <Paper square className={classes.tabBarContainer}>
            <Tabs
              variant="fullWidth"
              value={selectedTab}
              onChange={handleTabChange}
            >
              <Tab label={strings("ALL")}{...a11yProps(0)} />
              <Tab label= {strings("RECEIVE_PAYMENT")} {...a11yProps(1)} />
              <Tab label={strings("GIVE_PAYMENT")} {...a11yProps(2)} />
            </Tabs>
          </Paper>
        </Grid>

        <Grid container item>
          <TabPanel value={selectedTab} index={0}></TabPanel>
          <TabPanel value={selectedTab} index={1}>
            Item two
          </TabPanel>
          <TabPanel value={selectedTab} index={2}>
            Item three
          </TabPanel>
        </Grid>
      </Grid>
    </>
  );
};


export default UserStatement;
