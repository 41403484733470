import { makeStyles } from "@material-ui/core/styles";
import { themeStyleSheet, themeColors } from "../../../constants";
const useStyles = makeStyles((theme) => ({
   
    headerMainContainer: {
        flexDirection: "row",
        height: "64px",
        width: "100%",
        backgroundColor: themeColors.appColor,
      },
  
      leftIcon: {
        color: "#fff",
        fontSize: 25,
        marginLeft:"10px"
      },
      menuButton :{
        
 
      },
      titleText:{
        color: themeStyleSheet.fontColor,
        color: '#fff',
        fontSize: props=> props.subTitle ?  themeStyleSheet.fontSize18 :themeStyleSheet.fontSize20,
     
      },

      
      
}));

export default useStyles;
