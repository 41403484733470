import { useHistory } from "react-router";
import { strings } from "../../../../i18n";
import { useDrawerData } from "./useDrawerData";

export const useDrawerRoutes = (setLoading) => {
  const { logout } = useDrawerData(setLoading);
  const history = useHistory()
  
  const drawerRoutes = [
    {
      key: "home",
      icon: "dashboard",
      text: strings("HOME"),
      onClick: () => { history.push("/dashboard") },
    },
    {
      key: "account",
      icon: "account_circle",
      text:  strings("ACCOUNT"),
      onClick: () => { history.push("/createProfile") },
    },
    {
      divider: true,
    },
    {
      key: "contact_us",
      icon: "help_outline_outlined",
      text:    strings("CONTACT_US") ,
      onClick: () => {
        window.open("https://wa.me/923180110801?text=Hi!%20I%20need%20help%20using%20the%20CreditBook%20app", '_blank');
      }
    },

    {
      divider: true,
    },
    {
      key: "logout",
      icon: "power_settings_new",
      text:  strings("LOGOUT"),
      onClick: logout,
    },
  ];

  return {
    drawerRoutes,
  };
};
