import { makeStyles } from "@material-ui/core/styles";
import { themeStyleSheet, themeColors } from "../../../constants";
const useStyles = makeStyles((theme) => ({
    notFoundHeading:{
        fontSize: 28, 
        textAlign: "center",
        color: themeColors.appColor, 
        fontWeight:500
  },
  notFoundText: {
    fontSize: 18,
    marginTop: 15,
    textAlign: "center",
    color: themeColors.appColor,
  },
}));

export default useStyles;
