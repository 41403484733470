import { makeStyles } from "@material-ui/core/styles";
import { themeStyleSheet, themeColors } from "../../../constants";
const useStyles = makeStyles((theme) => ({
  mainCont: {
    width: "100%",
    [`${theme.breakpoints.down("sm")} `]: {
        height:"100vh",
        maxWidth: "100%",
 
    },
    position: "fixed",
    height: "80%",
    maxWidth: "500px",
    backgroundColor: "#fff",
    padding:"0px",
    margin:"0px",
    maxWidth: "500px",
  },
  transactionSMSText:{
    fontWeight:"bold",
       
  },

  root: {
    [`${theme.breakpoints.down("sm")} `]: {
  
      maxWidth:"100%",
    },
    overflow: "auto",
    marginTop:"10px",
    height: "calc( 100% - 80px )",
    maxWidth:"100%",
  },
  sampleSMSCont:{
    border:"1px solid ",
    borderColor: themeColors.loginMobilePlaceholder,
    borderRadius: "6px",
    marginLeft:"10px",
    marginRight:"10px",
   
    
    padding: 20
  }, 

  chooseLangText: {
    fontSize: 22,
    color: themeColors.appColor,
    marginTop: "30px",
  },
  languageItemCont: {
    margin: "10px 0px",
    display: "flex",
    width: "100%",
    padding: "0px 20px",
  },
  langText: {
    fontSize: 18,
    color: themeColors.langText,
    textAlign: "left",
  },
  langCheckIcon: {
    fontSize: 25,
    color: themeColors.langCheckIcon,
  },

  confirmButton: {
 
    position:"sticky",
    marginTop:"20px",

    bottom:"10px",
    width:"80%",
    left: 0,
    right: 0,
    margin: "0px auto",
    padding: "8px 40px",
    borderRadius: "15px",
    backgroundColor: themeColors.appColor,
    "&:hover": {
      backgroundColor: "#353030",
    },
  },
}));

export default useStyles;
