import { USER_STATEMENT } from "./types";

export default class UserStatement {
    
    static getUserTransaction(value) {
        return {
            type: USER_STATEMENT.GET_USER_TRANSACTION,
            value
        }
    }

}