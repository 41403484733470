import { makeStyles } from "@material-ui/core/styles";
import { themeStyleSheet, themeColors } from "../../../constants";
const useStyles = makeStyles({
  resendCodeText: {
    fontSize: "1rem",
    fontWeight: 700,
    marginLeft: "4px",
    color: (props) =>
      props.count === 0
        ? themeColors.appColor
        : themeColors.loginMobilePlaceholder,
    disabled: "true",
    "&:hover": {
      color: (props) => props.count === 0 && themeColors.loginMobilePlaceholder,
      cursor: (props) => (props.count === 0 ? "pointer" : "auto"),
    },
    "&:active": {
      color: (props) => props.count === 0 && themeColors.loginMobilePlaceholder,
    },
  },
});

export default useStyles;
