import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import RS_ICON from "../../../assets/rs.svg";
import {
  Grid,
  TextField,
  Typography,
  Icon,
  ButtonBase,
  Button,
  Box,
  Modal,
  Divider,
  IconButton,
} from "@material-ui/core";

import useStyles from "./styles";

import { strings } from "../../../i18n";
import DrawerHeader from "../../common/DrawerHeader";
import Loader from "../../common/Loader";
import { formattedDate } from "../../../utils";
import CreateTransaction from "../CreateTransaction";
import TransactionsMiddleware from "../../../store/middleware/Transactions";
import DeleteDialog from "./Dialogs/DeleteDialog";

const TransactionInfo = ({ type, selectedTransaction, account, onModalClose }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  let { amount, note, } = selectedTransaction || {}
  let chosenDate = formattedDate(selectedTransaction?.transaction_timestamp?.toDate() || new Date())
  let img_path = selectedTransaction?.transaction_image || ""
  const [isCreateTransactionOpen, setIsCreateTransactionOpen] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);



  const deleteTransaction = async () => {
    setLoading(true)
    let updated_data = {
      type: "deleted",
    }
    dispatch(TransactionsMiddleware.update(selectedTransaction.id, updated_data)).then(() => {
      setLoading(false)
      onModalClose()
    }).catch((err) => {
      setLoading(false)
      console.log(err)
    })

  }
  const toggleTransactionModal = () => {
    setIsCreateTransactionOpen(!isCreateTransactionOpen)
  }
  return (
    <>
      <Grid direction="column" container xs={12} className={classes.mainCont}>
        <Loader loading={loading} />

        <Grid
          direction="row"
          container
          xs={12}

          justify="center"
          className={classes.container}
        >
          <DrawerHeader
            title={strings("TRANSACTION_INFO")}
            leftIcon={"arrow_backward"}
            leftIconFunc={onModalClose}
          />
          {img_path &&
            < img className={classes.transaction_image} src={img_path} />
          }
          <Grid
            container
            item
            alignItems="flex-end"
            direction="row"
            justify="flex-start"
            className={classes.rowCont}
          >
            <img className={classes.amountIcon} src={RS_ICON} />
            <Box className={classes.rowItemCont}>
              <Box className={classes.amountCont}>
                <Typography className={classes.titleText} noWrap>
                  Amount
              </Typography>
                <Typography className={classes.amountText} noWrap>
                  {amount}
                </Typography>
              </Box>

              <IconButton onClick={toggleTransactionModal}>
                <Icon className={classes.editIcon}>edit</Icon>
              </IconButton>
            </Box>
          </Grid>

          <Grid
            container
            item
            alignItems="flex-end"
            direction="row"
            justify="flex-start"
            className={classes.rowCont}
          >
            <Icon className={classes.calIcon}>description</Icon>
            <Box className={classes.rowItemCont}>
              <Box className={classes.amountCont}>
                <Typography className={classes.titleText} noWrap>
                  {strings("NOTE")}
                </Typography>

                <Typography className={classes.noteText} noWrap>
                  {note || strings("NOTE_NOT_SPECIFIED")}
                </Typography>
              </Box>
              <IconButton onClick={toggleTransactionModal}>
                <Icon className={classes.editIcon}>edit</Icon>
              </IconButton>
            </Box>
          </Grid>

          <Grid
            container
            item
            alignItems="flex-end"
            direction="row"
            justify="flex-start"
            className={classes.rowCont}
          >
            <Icon className={classes.calIcon}>event</Icon>
            <Box className={classes.rowItemCont}>
              <Box className={classes.amountCont}>
                <Typography className={classes.titleText} noWrap>
                  Date
              </Typography>
                <Typography className={classes.dateText}>{chosenDate}</Typography>
              </Box>
              <IconButton onClick={toggleTransactionModal}>
                <Icon className={classes.editIcon}>edit</Icon>
              </IconButton>
            </Box>
          </Grid>
          <Grid container item direction="row" justify="center">
            <Box className={classes.syncCont}>
              <Icon className={classes.cloudIcon}>cloud_queue</Icon>
              <Typography>{strings("SYNC_SUCESSFULLY")}</Typography>
            </Box>
          </Grid>
          <Grid container item direction="row" justify="center" alignItems="center" alignContent="center" xs={12}>
            <Button
              variant="contained"
              color="primary"
              className={classes.customerButton}
              onClick={toggleTransactionModal}
            >
              {strings("Edit")}
            </Button>
          </Grid>
          <Grid container item direction="row" justify="center" alignItems="center" alignContent="center" xs={12}>
            <Button
              variant="contained"
              color="primary"
              className={classes.deleteAccountBtn}
              onClick={() => { setShowDeleteDialog(true) }}
            >
              {strings("Delete")}
            </Button>
          </Grid>
        </Grid>


      </Grid>
      <Modal
        open={isCreateTransactionOpen}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        onClose={onModalClose}
      >
        <CreateTransaction
          selectedTransaction={selectedTransaction}
          account={account}
          type={type}
          onModalClose={onModalClose}
        />
      </Modal>


      <DeleteDialog
        open={showDeleteDialog}
        onClose={() => setShowDeleteDialog(false)}
        onAccept={deleteTransaction}
        onReject={() => setShowDeleteDialog(false)}
      />
    </>
  );
};

export default TransactionInfo;
