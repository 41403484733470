import { makeStyles } from "@material-ui/core/styles";
import { themeStyleSheet, themeColors } from "../../../constants";
const useStyles = makeStyles((theme) => ({
  numpadBtnContainer: {
    height: "40px",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    background:"#f1f3f4",
     margin:"5px"
  },
  calculatorNumpad: {
    fontWeight: "bold",
    textAlign: "center",
    fontSize: "24px",
    
  },
  icon:{
    fontSize: themeStyleSheet.fontSize30,
    color: "#202124"
  },
  iconCont:{
        background:"#dadce0"
  },
  equalBtnContainer:{
 
    background:themeColors.appColor,
   },
  equalIcon:{
 
    color: "white"
  }
}));

export default useStyles;
