//    Collection: Customers        //    

// export var account = {
//         "user_id": "",            // User Id from Users Collection,
//         "name": "",               // Mandotoary
//         "nameLowerCase": "",
//         "mobile_no": "",
//         "address": "",
//         "profile_image": "",
//         "send_reminder": false,
//         "send_reminder_on": "",    // 'whatsapp' or 'SMS'
//         "send_transaction_sms": false,
//         "send_transaction_whatsapp": false,
//         "is_account_active": true,
//         "notification_language": '',
//         "balance": 0,
//         "business_name": "",
//         "last_activity": "",   //date
//         "last_transaction": null,
//         "img_url": "",
//         "transactions": [                // Array of Transaction IDS From Transaction collection

//         ],
//         "type": "default",  // default,deleted...
//         "credit_limit": null

// }

export var customer = {                                // ##### SCHEMA NAME RENAMED ####
        "user_id": "",                                 // User Id from Users Collection,
        "name": "",
        "nameLowerCase": "",
        "mobile_no": "",
        "business_name": "",
        "last_activity": "",
        "last_transaction": null,
        "img_url": "",
        "type": "default",                              // default,deleted...
        "credit_limit": null,

        "is_send_transactional_sms_active": true,       //                                                      ##### ADDED #####       
        "preferred_language": "roman_ur",                       //                                                      ##### ADDED #####   
        "no_of_credits": 0,                             // Green (advance) transaction                          ##### ADDED #####  
        "amount_of_credits": 0,                         // Total amount of Green (advance) transactions         ##### ADDED #####  
        "no_of_debits": 0,                              // Red (udhaar) transaction                             ##### ADDED #####  
        "amount_of_debits": 0,                           // Total amount of Red (udhaar) transactions           ##### ADDED #####  
        "no_of_transactions": 0,                        // no_of_debits + no_of_credits                         ##### ADDED #####
        "amount_of_total_transactions": 0,              //  |amount_of_credits| + |amount_of_debits|            ##### ADDED #####
        "is_reminder_sent": false,

        "is_reminder_active": false,                    //                                                      ##### RENAMED FROM 'send_reminder' ####              
        "reminder_date": null,                          //                                                      ##### RENAMED FROM 'send_reminder_on' ####   
        "current_balance": 0,                           //  amount_of_credits + amount_of_debits                ##### RENAMED FROM 'balance' ####
        "existing_customer": false                      //  If its user's old customer from any other app like khatabook, digikhata etc

        // "address": "",                               //                                                      ##### DELETED #####
        // "profile_image": "",                         //                                                      ##### DELETED #####
        // "is_account_active": true,                   //                                                      ##### DELETED #####
        // "transactions": [],                          //                                                      ##### DELETED #####  
        // "send_transaction_sms": false,               //                                                      ##### DELETED #####   
        // "send_transaction_whatsapp": false,          //                                                      ##### DELETED #####  
        // "notification_language": null                //                                                      ##### DELETED #####  
}
