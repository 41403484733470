import { makeStyles } from "@material-ui/core/styles";
import { themeStyleSheet, themeColors } from "../../../constants";
const useStyles = makeStyles((theme) => ({
  headerMainContainer: {
    flexDirection: "row",
    height: "64px",
    width: "100%",
    backgroundColor: themeColors.appColor,
  },
  leftIconCont: {
    [`${theme.breakpoints.up("md")} `]: {
    display: props => props.forceShowLeftIcon ? "block": "none",
    },
    [`${theme.breakpoints.down("sm")} `]: {
      display: "block",
    },

  },
  leftIcon: {
    color: "#fff",
    fontSize: themeStyleSheet.fontSize26,
  },

  headerImg: {
    height: 48,
    width: 48,
    [`${theme.breakpoints.down("sm")} `]: {
      marginLeft: "0px",
    },
    marginLeft: "10px",
  },
  titleTextContainer: {
    marginLeft: "1rem",
  },
  titleText: {
    color: themeStyleSheet.fontColor,
    color: "#fff",
    fontSize: (props) =>
      props.subTitle ? themeStyleSheet.fontSize18 : themeStyleSheet.fontSize20,
  },
  subTitleText: {
    fontSize: 14,
    color: "#fff",
  },
  rightIconCont: {
    position: "absolute",
    right: "0px",
  },
  rightIcon: {
    color: "#fff",
    fontSize: themeStyleSheet.fontSize26,
  },
}));

export default useStyles;
