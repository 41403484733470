import API from "../../config/lib";

export async function loginWithCustomToken(mobileNumber) {
  return API.get(`/login`, {
    params: {
      mobileNumber,
      platform: "web",
    },
    timeout: 80000,
  });
}

export async function resendVerificationCode(mobileNumber) {
  return API.get(`/resendVoiceOTP`, {
    params: { mobileNumber, platform: "web_app" },
  });
}

export async function getTokenFromUUID(uid) {
  return API.get(`/getTokenFromUUID`, {
    params: { uid },
    timeout: 80000,
  });
}

export async function validateLogin(mobileNumber, otpSeedFromUser) {
  return API.get(`/validateLogin`, {
    params: { mobileNumber, otpSeedFromUser },
    timeout: 80000,
  });
}
