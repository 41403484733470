import { useEffect, useState,useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLoggedInUser } from "../../../../store/selectors";
import UserMiddleware from "../../../../store/middleware/User";
import { _USERS_REF } from "../../../../constants";

export const useDrawerData = (setLoading) => {
  const dispatch = useDispatch();
  const user = useSelector(getLoggedInUser);

  const logout = useCallback(async () => {
    setLoading(true);
    await _USERS_REF.doc(user.id).update({ user_last_activity: new Date(), contextID: 'fake'  });
    dispatch(UserMiddleware.logout())
      .then((res) => {
        setLoading(false);
        console.log("Logout");
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);
   
  return {
    user,
    logout,
  };
};
