import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./app";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import reportWebVitals from "./reportWebVitals";
import './i18n';
Sentry.init({
  dsn: "https://c110c340f7f64c7f968eb69f39cb230f@o562824.ingest.sentry.io/5982352",
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
});

if (process.env.NODE_ENV !== "development")
    console.log = () => {};
ReactDOM.render(
  <React.StrictMode>
 
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
