import axios from "axios";
import { appTinyUrl, storage, themeColors } from "../constants";

export const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "June",
  "July",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
  

export const getLang = (lang) => {
  let langText = '';
  switch (lang) {
      case 'en':
          langText = "English";
          break;

      case 'si':
          langText = "سنڌي";
          break;

      case 'pu':
          langText = "پن٘جابی";
          break;

      case 'pa':
          langText = "پشتو";
          break;

      case 'ur':
          langText = "اردو";
          break;

      case 'roman_ur':
          langText = "Roman Urdu";
          break;
      default:
          langText = "English";
          break;
  }

  return langText;
}


export const seed = (num) => {
  let x = Math.sin(num) * 10000;
  return x - Math.floor(x);
};

export const NetInfo = async () => {
  var condition = navigator.onLine ? "online" : "offline";
  if (condition === "online") {
    console.log("ONLINE");
    fetch("https://www.google.com/", {
      // Check for internet connectivity
      mode: "no-cors",
    })
      .then(() => {
        console.log("CONNECTED TO INTERNET");
      })
      .catch(() => {
        console.log("INTERNET CONNECTIVITY ISSUE");
      });
  } else {
    console.log("OFFLINE");
  }
};
export const formatNum = (num) => {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
export const formattedTime = (timestamp) => {
  let dateObj = new Date(timestamp.toDate());
  var hours = dateObj.getHours();
  var minutes = dateObj.getMinutes();
  var ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
};
export const formattedDate = (timestamp) => {
  let dateObj = new Date(
    timestamp.seconds ? timestamp.seconds * 1000 : timestamp
  );
  var month = dateObj.getMonth() + 1; //months from 1-12

  var day = dateObj.getDate();

  var year = dateObj.getFullYear();

  return (
    (day.toString().length === 1 ? `0${day}` : day) +
    "-" +
    (month.toString().length === 1 ? `0${month}` : month) +
    "-" +
    year
  );
};

export const getTransactionalSMSText = (
  lang,
  transaction_type,
  business_name,
  transaction_amount,
  current_balance,
  user_mobile_no,
  transaction_timestamp
) => {
  let text = "";
  let mobile_number = `0${user_mobile_no.slice(3)}`;
  if (transaction_type === "debit") {
    switch (lang) {
      case "en":
        text = `You have taken Rs. ${transaction_amount} from ${business_name} (${user_mobile_no}). Your overall balanace is Rs ${current_balance}.`;
        break;
      case "roman_ur":
        text = `Amount: Rs. ${transaction_amount} liey hain ${business_name} (${user_mobile_no}) se.\nDate: ${formattedDate(
          transaction_timestamp
        )}.\ Total balance Rs. ${current_balance} hai.\n\nYeh message apko CreditBook ki janib se bheja gya hai.  Download krein muft mai! ${appTinyUrl}`;
        break;
      case "si":
        text = `توھان ${transaction_amount} رپيا ورتا آهن (${mobile_number}) ${business_name} کان. توھان جو ڪُل بيلينس ${current_balance} رپيا آھي.`;
        break;
      case "pa":
        text = `تاسو د (${mobile_number}) ${business_name} نه ${transaction_amount} روپۍ اخستې دي او ستاسو ټولې پېسې ${current_balance} دي`;
        break;
      case "pu":
        text = `تسی ${transaction_amount}: روپے لئے آ (${mobile_number}) ${business_name} توں۔ تہاڈا کل بیلنس :${current_balance} روپے اے`;
        break;
      case "ur":
        text = `آپ نے ${transaction_amount}: روپے لئے ہیں (${mobile_number}) ${business_name} سے. آپ کا کُل بیلنس :${current_balance} روپے ہے`;
        break;
      default:
        text = `Apne Rs. ${transaction_amount} liey hain (${user_mobile_no}) ${business_name} se. Apka kull balance Rs. ${current_balance} hai.`;
        break;
    }
  } else {
    switch (lang) {
      case "en":
        text = `You have given Rs. ${transaction_amount} to ${business_name} (${user_mobile_no}). Your overall balanace is Rs ${current_balance}.`;
        break;
      case "roman_ur":
        text = `Amount: Rs. ${transaction_amount} diey hain ${business_name} (${user_mobile_no}) ko\nDate: ${formattedDate(
          transaction_timestamp
        )}.\Total balance Rs: ${current_balance} hai.\n\nYeh message apko CreditBook ki janib se bheja gya hai.  Download krein muft mai! ${appTinyUrl}`;
        break;
      case "si":
        text = `توھان ${transaction_amount} رپيا ڏنا آهن (${mobile_number}) ${business_name} کي. توھان جو ڪُل بيلينس ${current_balance} رپيا آھي.`;
        break;
      case "pa":
        text = `تاسو (${mobile_number}) ${business_name} ته ${transaction_amount} روپۍ ورکړې دي او ستاسو ټولې پېسې ${current_balance} دي۔`;
        break;
      case "pu":
        text = `تسی ${transaction_amount}: روپے دتے آ (${mobile_number}) ${business_name} توں۔ تہاڈا کل بیلنس :${current_balance} روپے اے۔`;
        break;
      case "ur":
        text = `آپ نے ${transaction_amount}: روپے دیئے ہیں (${mobile_number}) ${business_name} کو. آپ کا کُل بیلنس :${current_balance}روپے ہے`;
        break;
      default:
        text = `Apne Rs. ${transaction_amount} diey hain (${user_mobile_no}) ${business_name} ko. Apka kull balance Rs: ${current_balance} hai.`;
        break;
    }
  }
  return text;
};
export const sendSMS = (phoneNumber, message) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        "http://13.235.114.165:3010/sms/sendMfaEOcean",
        {
          phoneNumber,
          message,
        },
        {
          headers: {
            authorization:
              "$2y$12$deMdGJOuM2mohhXrsvqjMOhljuSg2dm.rZvskmGr2bVSYVSOFWcnS",
            type: "Broker",
          },
        }
      )
      .then(function (response) {
        console.log("response.data", response.data);
        console.log("response.data.success", response.data.success);
        if (response.data.success) {
          resolve();
        } else {
          reject();
        }
      })
      .catch(function (error) {
        console.log(error, "error");
        reject();
      });
  });
};

export const formatMobileNum = (phoneNumber) => {
  if (phoneNumber.slice(0, 2) === "92")
    phoneNumber = `0${phoneNumber.slice(2)}`;
  else if (phoneNumber.slice(0, 3) === "+92")
    phoneNumber = `0${phoneNumber.slice(3)}`;
  return phoneNumber;
};

export const getRandomInt = (max) => {
  return Math.floor(Math.random() * Math.floor(max));
};

export const storeImageInFirebase = (imgType, imgId, imgFile) => {
  return new Promise((resolve, reject) => {
    const uploadTask = storage.ref(`${imgType}/${imgId}`).put(imgFile);
    uploadTask.on(
      "state_changed",
      (snapShot) => {
        console.log(snapShot);
      },
      (err) => {
        console.log(err);
        reject(err);
      },
      () => {
        storage
          .ref(imgType)
          .child(imgId)
          .getDownloadURL()
          .then((fireBaseUrl) => {
            console.log(
              "File has been uploaded to Firebase storage : ",
              fireBaseUrl
            );
            resolve(fireBaseUrl);
          })
          .catch((err) => {
            reject(err);
          });
      }
    );
  });
};

export const getSampleTextForSMSAlerts = (lang) => {
  let text = "";
  switch (lang) {
    case "en":
      text = "You have taken Rs. 500 from Malik General Store (03001234567). Your overall balanace is Rs -3000.";
      break;
    case "roman_ur":
      text = "Amount: Rs. 500 liey hain Malik General Store (03001234567) se Date: 01/11/2020. Total balance Rs:-3000 hai.";
      break;
    case "si":
      text = "توھان 500 رپيا ورتا آهن مَلِڪ جنرل اسٽور (03001234567) کان. توھان جو ڪُل بيلينس 3000- رپيا آھي.";
      break;
    case "pa":
      text = "تاسو د ملک جنرل سټور (03001234567) نه 500 روپۍ اخستې دي او ستاسو ټولې پېسې -3000 دي";
      break;
    case "pu":
      text = "تسی 500: روپے لئے آ ملک جنرل سٹور (03001234567) توں۔ تہاڈا کل بیلنس :-3000 روپے اے";
      break;
    case "ur":
      text = "آپ نے 500: روپے لئے ہیں ملک جینرل اِسْٹور(03001234567) سے. آپ کا کُل بیلنس :-3000 روپے ہے";
      break;
    default:
      text = "You have taken Rs. 500 from Malik General Store (03001234567). Your overall balanace is Rs -3000.";
      break;
  }
  return text;
}

export const formattedDateWithTime = (timestamp, notFirebaseDate) => {
  let dateObj = notFirebaseDate ? new Date() : new Date(timestamp.toDate());
  var month = dateObj.getUTCMonth() + 1; //months from 1-12
  var day = dateObj.getUTCDate();
  var year = dateObj.getUTCFullYear();

  var hours = dateObj.getHours();
  var minutes = dateObj.getMinutes();
  var ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  return (
    (day.toString().length === 1 ? `0${day}` : day) +
    "-" +
    months[month - 1] +
    "-" +
    year +
    " " +
    strTime
  );
};
 


export const getHTMLForUserStatementPDF = (customerData, transactionsData,  amount_of_total_credits, amount_of_total_debits, no_of_debits, no_of_credits, current_balance, startDate, endDate) => {
  let dateHtml = startDate && endDate ? `<h2 style="margin-bottom: 0px;">Start Date  : ${formattedDate(startDate)}</h2>
  <h2 style="margin-bottom: 0px;">End &nbsp  Date  : ${formattedDate(endDate)}</h2>` : ""
  let html = `<!DOCTYPE html style="padding: 0%; margin: 0%">
    <html lang="en" style="padding: 0%; margin: 0%">
      <head>
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>Document</title>
        <style>
          .container {
            display: table;
            width: 100%;
          }
    
          .container div {
            display: table-cell;
          }
    
          .entities .entities-heading td {
                          font - weight: bold;
            font-size: 20px;
          }
    
          table {
                          border - collapse: collapse;
          }
    
          tr.border_bottom td {
                          border - bottom: 2px solid #e5e5e5;
          }
        </style>
      </head>
    
      <body style="padding: 0%; margin: 0%">
        <h2 style="margin-bottom: 0px">${customerData?.business_name || customerData.name}</h2>
 
        <table width="100%">
          <tr valign="center">
            <td width="50%">
              <table style="width: 100%" class="entities">
                <tr class="entities-heading" align="center">
                  <td>${no_of_credits}</td>
                  <td>${no_of_debits}</td>
                </tr>
                <tr align="center">
                  <td>Credits</td>
    
                  <td>Debits</td>
                </tr>
              </table>
            </td>
            <td width="50%">
              <table width="100%">
                <tr align="right">
                  <td>
                    <div
                      style="
                        width: 150px;
                        height: auto;
                        border: 1px solid black;
                        border-radius: 15px;
                        padding: 13px;
                      "
                    >
                      <div style="font-weight: bold">Net Balance</div>
                      <div style="font-size: 18px; color: ${current_balance < 0 ? themeColors.amountDue : themeColors.amountPay};" >
                        PKR ${formatNum(current_balance)}
                      </div>
                    </div>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </table>
    
        <table style="margin-top: 24px" width="100%">
          <tr>
            <td width="58%" style="font-weight: 600; font-size: 20px" align="left">
              Transactions
            </td>
            <td
              width="20%"
              style="font-weight: bold; font-size: 20px"
              align="center"
            >
              You got
            </td>
            <td width="2%" style="font-weight: bold; font-size: 20px"></td>
    
            <td
              width="20%"
              style="font-weight: bold; font-size: 20px"
              align="center"
            >
              You gave
            </td>
          </tr>
         ${getHTMLForUsersTransactionsPDF(transactionsData   )}
    
          <tr>
            <td width="55%" style="font-weight: bold">
              <table width="100%">
                <tr>
                  <td style="font-weight: bold; font-size: 20px">Total</td>
                  <td
                    align="right"
                    style="font-weight: bold; font-size: 20px; padding-right: 8px"
                  >
                    PKR
                  </td>
                </tr>
              </table>
            </td>
    
            <td
              width="20%"
              align="center"
              style="
                font-weight: bold;
                background-color: #ceeae2;
                font-size: 20px;
                padding: 8px 11px;
              "
            >
            ${formatNum(amount_of_total_credits)}
            </td>
    
            <td
              width="5%"
              align="center"
              style="font-weight: bold; font-size: 20px; padding: 8px 11px"
            ></td>
    
            <td
              width="20%"
              align="center"
              style="
                font-weight: bold;
                background-color: #eacece;
                font-size: 20px;
                padding: 8px 11px;
              "
            >
            ${formatNum(amount_of_total_debits)}
            </td>
          </tr>
        </table>
      </body>
    </html>
    `
  return html;
}

export const getHTMLForUsersTransactionsPDF = (transactionsData   ) => {
  let html = ``;
  for (let i = 0; i < transactionsData.length; i++) {
    if (transactionsData[i].sub_type === "notification") continue

    html += ` <tr class="border_bottom">
<td width="55%" style="padding-top: 10px; padding-bottom: 5px">
  <div style="font-size: 15px; font-weight: bolder">${transactionsData[i].customer_name}</div>
  <div style="padding-top: 2px; color: #b1b1b1">
   ${formattedDateWithTime(transactionsData[i].transaction_timestamp)}
  </div>
</td>
<td
  bgcolor="#F5F5F5"
  width="20%"
  align="center"
  valign="center"
  style="
    padding-top: 10px;
    padding-bottom: 5px;
    color: #3bc098;
    font-weight: bolder;
    font-size: 20px;
  "
>
  ${transactionsData[i].transaction_type === "credit" ? formatNum(transactionsData[i].amount) : ''}
</td>

<td
  width="5%"
  align="center"
  valign="center"
  style="padding-top: 10px; padding-bottom: 5px"
></td>

<td
  bgcolor="#F5F5F5"
  width="20%"
  align="center"
  valign="center"
  style="
    padding-top: 10px;
    padding-bottom: 5px;
    color: #cb6363;
    font-weight: bolder;
    font-size: 20px;
  "
>
${transactionsData[i].transaction_type === "debit" ? formatNum(transactionsData[i].amount) : ''}
</td>
</tr>`
  }
  return html;
}


export const getHTMLForCustomerStatementPDF = (customerData, transactionsData,    amount_of_total_credits, amount_of_total_debits, no_of_debits, no_of_credits, current_balance, startDate, endDate) => {
  let dateHtml = startDate && endDate ? `<h2 style="margin-bottom: 0px;">Start Date  : ${formattedDate(startDate)}</h2>
                    <h2 style="margin-bottom: 0px;">End &nbsp  Date  : ${formattedDate(endDate)}</h2>` : ""
  let html = `<!DOCTYPE html style="padding: 0%; margin: 0%">
    <html lang="en" style="padding: 0%; margin: 0%">
      <head>
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>Document</title>
        <style>
          .container {
            display: table;
            width: 100%;
          }
    
          .container div {
            display: table-cell;
          }
    
          .entities .entities-heading td {
                          font - weight: bold;
            font-size: 20px;
          }
    
          table {
                          border - collapse: collapse;
          }
    
          tr.border_bottom td {
                          border - bottom: 2px solid #e5e5e5;
          }
        </style>
      </head>
    
      <body style="padding: 0%; margin: 0%">
        <h2 style="margin-bottom: 0px">${customerData?.business_name || customerData.name}</h2>
           
        <table width="100%">
          <tr valign="center">
            <td width="50%">
              <table style="width: 100%" class="entities">
                <tr class="entities-heading" align="center">
                  <td>${no_of_credits}</td>
                  <td>${no_of_debits}</td>
                </tr>
                <tr align="center">
                  <td>Credits</td>
    
                  <td>Debits</td>
                </tr>
              </table>
            </td>
            <td width="50%">
              <table width="100%">
                <tr align="right">
                  <td>
                    <div
                      style="
                        width: 150px;
                        height: auto;
                        border: 1px solid black;
                        border-radius: 15px;
                        padding: 13px;
                      "
                    >
                      <div style="font-weight: bold">Net Balance</div>
                      <div style="font-size: 18px; color: ${current_balance < 0 ? themeColors.amountDue : themeColors.amountPay};" >
                        PKR ${formatNum(current_balance)}
                      </div>
                    </div>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </table>
    
        <table style="margin-top: 24px" width="100%">
          <tr>
            <td width="58%" style="font-weight: 600; font-size: 20px" align="left">
              Transactions
            </td>
            <td
              width="20%"
              style="font-weight: bold; font-size: 20px"
              align="center"
            >
              You got
            </td>
            <td width="2%" style="font-weight: bold; font-size: 20px"></td>
    
            <td
              width="20%"
              style="font-weight: bold; font-size: 20px"
              align="center"
            >
              You gave
            </td>
          </tr>
         ${getHTMLForCustomerTransactionsPDF(transactionsData,  )}
    
          <tr>
            <td width="55%" style="font-weight: bold">
              <table width="100%">
                <tr>
                  <td style="font-weight: bold; font-size: 20px">Total</td>
                  <td
                    align="right"
                    style="font-weight: bold; font-size: 20px; padding-right: 8px"
                  >
                    PKR
                  </td>
                </tr>
              </table>
            </td>
    
            <td
              width="20%"
              align="center"
              style="
                font-weight: bold;
                background-color: #ceeae2;
                font-size: 20px;
                padding: 8px 11px;
              "
            >
            ${formatNum(amount_of_total_credits)}
            </td>
    
            <td
              width="5%"
              align="center"
              style="font-weight: bold; font-size: 20px; padding: 8px 11px"
            ></td>
    
            <td
              width="20%"
              align="center"
              style="
                font-weight: bold;
                background-color: #eacece;
                font-size: 20px;
                padding: 8px 11px;
              "
            >
            ${formatNum(amount_of_total_debits)}
            </td>
          </tr>
        </table>
      </body>
    </html>
    `
  return html;
}

export const getHTMLForCustomerTransactionsPDF = (transactionsData  ) => {
  let html = ``;
  for (let i = 0; i < transactionsData.length; i++) {
    if (transactionsData[i].sub_type === "notification") continue

    html += ` <tr class="border_bottom">
<td width="55%" style="padding-top: 10px; padding-bottom: 5px">
  <div style="font-size: 15px; font-weight: bolder">${transactionsData[i].note}</div>
  <div style="padding-top: 2px; color: #b1b1b1">
   ${formattedDateWithTime(transactionsData[i].transaction_timestamp)}
  </div>
</td>
<td
  bgcolor="#F5F5F5"
  width="20%"
  align="center"
  valign="center"
  style="
    padding-top: 10px;
    padding-bottom: 5px;
    color: #3bc098;
    font-weight: bolder;
    font-size: 20px;
  "
>
  ${transactionsData[i].transaction_type === "credit" ? formatNum(transactionsData[i].amount) : ''}
</td>

<td
  width="5%"
  align="center"
  valign="center"
  style="padding-top: 10px; padding-bottom: 5px"
></td>

<td
  bgcolor="#F5F5F5"
  width="20%"
  align="center"
  valign="center"
  style="
    padding-top: 10px;
    padding-bottom: 5px;
    color: #cb6363;
    font-weight: bolder;
    font-size: 20px;
  "
>
${transactionsData[i].transaction_type === "debit" ? formatNum(transactionsData[i].amount) : ''}
</td>
</tr>`
  }
  return html;
}
