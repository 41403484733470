import { makeStyles } from "@material-ui/core/styles";
import { themeStyleSheet, themeColors } from "../../../constants";
const useStyles = makeStyles((theme) => ({
    mainCont: {
        [`${theme.breakpoints.down("sm")} `]: {
            minHeight: "100vh",
            maxWidth: "100%",

        },
        position: "fixed",
        maxHeight: "80%",
        maxWidth: "500px",
        backgroundColor: "#fff",
        padding: "0px",
        margin: "0px",
 
    },
    container: {

        overflow: "auto",

        [`${theme.breakpoints.down("sm")} `]: {
            maxHeight: "calc( 100%   )",
        },
        maxHeight: "calc( 100%  )",
    },
    transaction_image: {
        height: "200px",
        width: "100%",
        objectFit: "cover",
        marginBottom: "10px"
    },
    rowCont: {
        padding: "10px",
 
    },
    rowItemCont: {
        borderBottom: " 1px solid  #9CAAA9",
        display: "flex",
        flexDirection: "row",
        flex: 1,
        justifyContent: "space-between",
        alignItems: "center",
        paddingBottom: "5px"
    },
    titleText: {
        color: "#9CAAA9",
        fontSize: "10px",
        marginBottom: "2px"
    },
    amountCont: {
        display: "flex",
        flexDirection: "column",
    },
    amountIcon: {
        height: "18px",
        marginBottom: "10px",
        marginRight: "15px"
    },
    amountText: {

        fontWeight: "bold",
        fontSize: "18px",
        color: props => props.transaction_type === 'debit' ? themeColors.amountDue : themeColors.amountPay,
        lineHeight: "22px"
    },
    datePickerMainCont: {
        display: "flex",
        alignItems: "center",

        border: "1px solid",
        borderColor: themeColors.amountInputBorder,
        borderRadius: 4,
        cursor: "pointer",
        padding: "8px 15px",
        width: "fit-content",
    },
    dateText: {
        fontSize: 14,
        color: '#6E7781',
        fontWeight: "500"
    },
    calIcon: {
        fontSize: "25px",
        marginBottom: "8px",
        marginRight: "15px",
        color: "#075E55"
    },
    noteMainCont: {

        borderTop: " 1px solid rgba(0,0,0,0.10)",
        marginTop: "10px",
        padding: "10px 0px"

    },
    noteCont: {

    },
    noteTitle: {
        fontSize: "14px",
        color: 'rgba(0,0,0,0.85)',
        fontWeight: 'bold'
    },
    noteText: {
        fontSize: "14px",
        color: "#6E7781",
        fontWeight: "500"
    }, editIcon: {
        fontSize: 22,
        color: "#9CAAA9"
    },
    syncCont: {
        backgroundColor: "#D4E8E9",
        display: "flex",
        flexDirection: "row",
        padding: "10px 15px",
        alignItems: "center",
        borderRadius: "5px",
        maxHeight:"50px"
    },
    cloudIcon: {
        marginRight: "5px",
        fontSize: 20,
        color: "#3BBBEB",

    },
    deleteAccountBtn: {
        marginTop: "10px",
        width: "250px",
        marginBottom: "10px",
        padding: "8px 40px",
        borderRadius: "15px",
        backgroundColor: themeColors.amountDue,
        "&:hover": {
            backgroundColor: "#353030",
        },
    },
    customerButton: {
        marginTop: "50px",
        width: "250px",
        padding: "8px 40px",
        borderRadius: "15px",
        backgroundColor: themeColors.appColor,
        "&:hover": {
            backgroundColor: "#353030",
        },
    },
    btnCont: {
        border: "1.5px solid #D22D2F",
        display: "flex",
        justifyContent: "center",
        flexDirection: "row",
        width: "200px",
        height: "40px",
        alignItems: "center",
        borderRadius: "5px",
        margin: "10px 0px",
        "box-shadow": "0 2px 8px 0 rgba(0, 0, 0, 0.2), 0 4px 20px 0 rgba(0, 0, 0, 0.1)"

    },
    trashIcon: {
        color: "#D22D2F",
        marginRight: "5px",
        fontSize: 20,
    }
}));

export default useStyles;
