export default {
    LOGIN_VERIFICATION_CODE_TEXT: "Ek Verification code behja hai is per",
    LOGIN_COUNTRY_TEXT: "Pakistan (+92) / mulk chune",
    LOGIN_PHONENUMBER: "Phone Number",
    WELCOME: "Khush amdeed",
    LOGIN: "Log in",
    CHANGE_LANGUAGE: "Zaban badlo",
    SELECT_LANGUAGE: "Zaban chunno",
    ADD_ACCOUNT: "Naya Account Shamil karien",
    PAY: "Ada",
    DUE: "Baqayyah",
    CLEAR: "Clear",
    PAID: "Addaigeh hogai",
    ADDED_ON: "Shamil kara",
    TOOK_LOAN: "Karza lia",
    RUPEES: "Rupees",
    SEARCH_CONTACTS: "Account Talaash karien",
    SALAM: "Salam",
    NEW_CONTACT: "Naya Account",
    GIVE_PAYMENT: "Aapne Diye",
    RECEIVE_PAYMENT: "Aapne Liye",
    CHOOSE_YOUR_LANGUAGE: "Apni zabaan chunne",
    NEXT: "Agaey",
    BACK: "pechy",
    VERIFICATION_CODE: "Tasdeeqi Code",
    VERIFICATION_CODE_MSG: "Is number per ek tasdeeqi code SMS ky zariyay bheja hai",
    A_verification_code_will_be_sent_on_your_number_via_SMS_Service_charges_may_apply: "Ek tasdeeqi Code ap ky number per bheja jaeyga. SMS charges laagoo ho sakte hain..",
    DIDNT_GET_CODE: "Tasdeeqi Code nhi mila ?",
    RESEND_CODE: "Code dobarah behjien",
    ENTER_MOBILE_NUMBER: "Mobile Number darj karien",
    PAKISTAN_92: "Pakistan (+92)",
    PERSONAL_INFORMATION: "Zaati Malumaat",
    GET_STARTED: "Shuro karien",
    LOGOUT: "Log out",
    HOME: "Home",
    ADD: "Add",
    NO_ACCOUNT_FOUND_NAMED: "- is naam ka koi account nhi hai",
    CONTACTS_FROM_PHONEBOOK: "Phonebook ky contacts",
    SETTINGS: "Settings",
    ABOUT_US: "Humare mutaliq",
    PRIVACY_POLICY: "Razdari ki policy",
    HELP: "Madad",
    CONTACT: "Rabta",
    SORT_NAME: "Naam",
    SORT_AMOUNT: "Raqam",
    SORT_LATEST: "Tazaah tareen",
    SORT_PAYMENT_DUE: "Muqararah Adeygeh",
    NAME: "Naam",
    PHONE_NUMBER: "Phone number",
    BUSINESS_NAME: "Qarobaar ka naam",
    TODAY: "Aaj",
    YESTERDAY: "Pechla din",
    ENTER_AMOUNT: "Raqam dalien",
    WRITE_NOTE_HERE: "Yahan note likhien",
    FULL_NAME: "Pura naam",
    EMAIL_ADDRESS: "Email address",
    BUSINESS_TYPE: "Qarobaar ki Iqsaam",
    TRANSACTION_INFO: "Lenden ki maloomat",
    SYNC_SUCESSFULLY: "update qamyaab",
    NOT_SYNC: "Update naqamyaab",
    SHARE_TRANSACTION: "Lenden (ki maloomat) bantien",
    DELETE_TRANSACTION: "Lenden delete",
    NOTE_NOT_SPECIFIED: "Note wazay nhi hai",
    ADVANCE: "Advance",
    ACCOUNT: "Account",
    WHATSAPP: "Whatsapp",
    SHARE: "Share",
    VERSION: "Version",
    UPDATE: "Update",
    UPDATE_ACCOUNT: "Update account",
    ACCOUNTS: "Accounts",
    USER_STATEMENT: "Sarif Statement",
    PROFILE: "Profile",
    LANGUAGE: "Zaban",
    BALANCE: "Balance",
    NOTE: "Note",
    DELETE_ACCOUNT: "Account Delete",
    NET_BALANCE: "Total Balance",
    CREDITS: "Credits",
    DEBITS: "Debits",
    PAYMENTS: "Adaeygehyaan",
    DO_YOU_WANT_TO_DELETE_THIS_TRANSACTION: "Kia apko is lenden ko delete karna hai ?",
    TRANSACTION_HAS_BEEN_DELETED: "Lenden delete hogai",
    RESTORE_TRANSACTION: "Lenden bahal karen",
    DO_YOU_WANT_TO_RESTORE_THIS_TRANSACTION: "Kia ap is lenden ko bahal karna chahty hain ?",
    TRANSACTION_HAS_BEEN_RESTORED: "lenden bahal go hai",
    VERIFIED_BY_CREDIT_BOOK: "CreditBook sey tasdeeq shuda",
    AMOUNT_GIVEN: "di gai raqam",
    AMOUNT_RECEIVED: "Wasooli raqam",
    PLEASE_ENTER_AMOUNT: "Bara e meherbani raqam darj karien",
    PLEASE_ENTER_MOBILE_NUMBER: "Bara e meherbani mobile number darj karien",
    PLEASE_CHECK_INTERNET_CONNECTION: "Bara e meharbani internet connection check karen",
    SOMETHING_WENT_WRONG: "Aray! Kuch kahrabi ai hai",
    CODE_RESENT: "Code dobarah bhejdia",
    UPDATE_NOTE: "Update note",
    ADD_CUSTOMER: "Sarif Add karen",
    SEARCH_CUSTOMERS: "Talash sarif",
    NEW_CUSTOMER: "Naya sarif",
    NO_CUSTOMER_FOUND_NAMED: "Sarif nhi mila",
    UPDATE_CUSTOMER: "Update sarif",
    DELETE_CUSTOMER: "Delete sarif",
    CUSTOMERS: "Sarifien",
    SEND_REMINDER: "yad dihani krwaien",
    SHARE_STATEMENT: "share statement",
    CREDIT_LIMIT: "Credit ki had",
    CONTACT_US: "Hum se rabta",
    ADD_FROM_PHONEBOOK: "Phonebook se add ",
    SEARCH_FROM_PHONEBOOK: "Phonebook se talash",
    SORT_TYPE: "Tazatareen",
    NO_TRANSACTIONS_AVAILABLE: "Koi lain dien mojood nhi",
    TRANSACTION_MESSAGE_WHEN_GIVING: "apny karzh lia hai Rs:{qimat} , {dukan naam} se, {tareekh} ko. Ye behja gya hai CreditBook se. Abhi Creditbook download karien aur apni khatakibat ko mousar tareeqy se sambhalien. ",
    TRANSACTION_MESSAGE_WHEN_TAKING: "Apne Rs. {qimat} diye hain, {dukan naam} ko, {tareekh} ko. Ye behja gya hai CreditBook se. Abhi Creditbook download karien aur apni khatakibat ko mousar tareeqy se sambhalien. ",
    SHARE_MSG: "Abhi CreditBook Download karien aur apni khata kitab ko mousar tareeqy se sambhalien. http://onelink.to/z8xrde",
    REMINDER_WHEN_CREDIT: "ap ka credit Rs. {account balance} rehta hai {Dukhan naam} per. CreditBook se behja gya hai. Abhi CreditBook Download karien aur apni khata kitab ko mousar tareeqy se sambhalien. http://tiny.cc/rmmsmz",
    REMINDER_WHEN_BALANCE: "apka account balance Rs. {account.balance}, {Dukhan naam} per. Abhi CreditBook Download karien aur apni khata kitab ko mousar tareeqy se sambhalien. http://tiny.cc/rmmsmz",
    CONTACT_US_MESSAGE: "Salam! mujhy madad chaiyay Creditbook app ky istamal mai",
    CHANGE_LANGUAGE_CONFIRMATION: "Is khousosiyat ky liey aap ko app band kare k, dobarah shuro karna hoga. Kia ap karna chahty hain ?",
    USER_CONSENT_MESSAGE: "Sign up karky aap humary sharait o zawabit aur razdari ki policy se itafaq rakhty hain.",
    START_DATE: "Tareekh Shuro",
    END_DATE: "Tareekh Khatam",
    THIS_MONTH: "yeh Maheena",
    CUSTOM_RANGE: "Apne marzi k mutaabiq",
    CREDIT_LIMIT_EXCEED_MSG: "Credit hadh se tajawuz, kia ap jari rakhna pasand karien gey ?",
    WARNING: "khabardar",
    INVALID_MOBILE_NO_ERROR: "Ghalat mobile # drust format : 03451234567",
    FIELD_REQUIRED: "field darkar",
    CREDIT_LIMTI_CANT_BE_ZERO: "credit ki had sifar (0) nhi ho sakti (agar ap had muqarar karna chahtey hain tw isko khali chordien)",
    DO_YOU_WANT_TO_DELETE_THIS_CUSTOMER: "kia ap is sarif ko delete karna chahtey hain ?",
    ENTER_YOUR_NOTE_HERE: "apna account yahan darj karien",
    ADD_YOUR_FIRST_CUSTOMER: 'Apna pehla sarif dakhil karo.',
    PRESS_THE_BUTTON_FOR_ADDING_YOUR_FIRST_CUSTOMER: "Apne pehly sarif ko add karny ky liey button ko dabaien.",
    GO_TO_DASHBOARD: "Dashboard per jaien",
    TOOLTIP_TEXT_ONBOARDING_WHEN_ADDED_FIRST_CUSTOMER: "Aapne apna pehla Sarif darj karlia hai! Paison ki waqoosi ya daeygeh ky liey Sarif per click karen.",
    TOOLTIP_TEXT_ONBOARDING_WHEN_ADDED_FIRST_TRANSACTION: 'Aapne lien dien daarj karli hai! Creditbook mai aur bhi khususiyat mojood hain. Mazeed janney ky liey "?" icon per click karien madatgar videos dekhny ky liey.',
    REMINDER_HAS_BEEN_SENT: "Yadihani karwadi!",
    MOBILE_NO_NOT_ADDED: "Mobile number mojood nhi",
    MOBILE_NO_NOT_ADDED_DESCRIPTION: "ka mobile number mojood nhi. Kia aap mobile number darj karna pasand kariengy?",
    SOMETHING_WENT_WRONG_WHILE_SENDING_SMS: "SMS bhejty waqt koi kharabi ai hai. Bare meherbani dobarah koshish keyjiyay!",
    REPORTS: "Reportain",
    REMINDERS: "Yadihaniyaan",
    PAYMENT_DUE_DATE: "Adaeygeh ki akhri tareekh",
    ACOUNT_CALL: 'Call',
    ACCOUNT_PAYMENT_REMINDER_DATE_SET: 'Adaeygeh ki Yadihani ki tareekh darj karien',
    AUTO_REMINDER: "Khud ba khud yadihani",
    REMINDER_DATE: "Yadihani Tareekh",
    FREE_SMS: "Muft SMS",
    REMIND_ALL: "Sab ko yadilaien",
    REMIND: "Yadilaien",
    REMINDER_SENT: "Yadihani bhej di gai!",
    SMS_ABOUT_TO_SEND_ALL: "Ap SMS bhej ny waly hain, sare un sarifean ko jinho ny baqayah raqam dyni hai! kia ap jari rakhna chahiengy? .Note : SMS srif un sarifeen ko behja jaeyga jinka number darj hai. ",
    APPLY_FILTERS: "Filters chalain",
    LOWEST_AMOUNT: "kam tareen raqam",
    HIGHEST_AMOUNT: "sab se xiada raqam",
    SORT_BY: "tarteeb bazareeyah",
    AMOUNT: "raqam",
    ADD_FILTERS: "Filters lagain",
    TRANSACTIONS_ALERT_SETTING: 'Lien dien atlah settings',
    TRANSACTION_ALERTS: "Lien dien alert",
    TRANSACTION_TOGGLE_MESSAGE: "Lien dien alert, SMS ky bazariyay apky sarif ko jati hai jab bhi lien dien amal mai ati hai",
    DATA_SECURITY_MESSAGE: "Apka Data aur Liendien muqamal tor per mehfooz hain",
    AUTO_REMINDER_MESSAGES: "Har roz subha ky 6 bjy yadihani khud ba khud bhej di jati hai",
    TODAY: "Ajj",
    SCHEDULED_FOR_LATER: "Bad ky liey muqarar",
    TO_BE_SENT: "Bhejna hai",
    APPLOCK: "App lock",
    CREATE_A_NEW_PIN: "Nai PIN bnaien",
    CONFIRM_PIN: "Tasdeek PIN",
    PIN_NOT_MATCHED_ERROR: "PIN mai mumaslat nhi - bra-e-meherbani dobarah darj karien",
    APPLOCK_ENABLED: "Applock lagadia",
    APP_LOCK_UPDATED: "Applock updated",
    REMOVE_APPLOCK: "Applock hataien",
    CHANGE_PIN: "Pin tabdeel",
    RE_ENTER_YOUR_PIN: "Pin dobarah Darj karien",
    REMOVE_PIN: "Pin hataien",
    INVALID_PIN_ERROR: "Ghalat pin, bra-e-meherbani dobarah darj karien",
    APP_LOCK_REMOVED: "Applock hat gya ",
    ENTER_YOUR_OLD_PIN: "Purani Pin darj karien",
    ENTER_YOUR_PIN: "Pin darj karien",
    CONTACT_SUPPORT: "Rabta karien madad ky liey",
    FORGOT_YOUR_PIN: "Pin bhool gaey?",
    CLICK_HERE: "Click yahan",
    DATA_BACKUP: "Data backup",
    YOUR_DATA_HAS_BEEN_BACKED_UP: "Apka data backup hogya!",
    DATA_BACKUP_DESC: "Internet se connect karien apny data ko humare servers per backup karny ky liey",
    LAST_BACKUP_AT: "_ akhri backup howa",
    SMS_ALERT_LANGUAGE: "SMS Alert Zaban",
    TRANSACTIONAL_SMS_SAMPLE: "Lien dien ky SMS ki misal",
    CONFIRM_LANGUAGE: "Zaban confirm",
    TOTAL: "Kul",
    CUSTOMER_STATEMENT: "Sarif Statement",
    ALL: "Sab",
    BUSINESS_CARD: "Business card",
    SAMPLE_CARD_DESIGN: "Aam card design",
    CREATE_A_NEW_BUSINESS_CARD: "Naya business card bnaien",
    ALTERNATE_PHONE_NUMBER: "Mutbadil phone number",
    LOCATION: "Location",
    AUTOMATICALLY_PICKS_UP_CURRENT_LOCATION_SHOWN_IN_GOOGLE_MAP_BELOW: "Nechy di gai location khud ba khud Google map ky zariyay chun lyga",
    SAVE_CARD: "Save card",
    SHARE: "Share",
    DELETE: "Delete",
    EDIT: "Edit",
    CREATE_NEW_CARD: "Naya card bnaien",
    COLLECT_PAYMENTS_3_TIMES_FASTER: "Adaeygeh 3 ghunah teezi se hasil karien!",
    SET_COLLECTION_DATES: "Tareekh tey karien hasil karny ki",
    REMINDERS_GO_OUT_1_DAY_BEFORE: "Yadihani ek din pehly krwadi jaey geh",
    COLLECT_PAYMENTS_3_X_FASTER: "Adaeygeh 3X teezi se hasil karien!",
    ADDRESS: "Pata",
    BUSINESS_CARD_HAS_BEEN_CREATED: "Business card ban gya!!",
    BUSINESS_CARD_HAS_BEEN_UPDATED: "Business card update hogya!!",
    DATE_RANGE: "Date Range",
    INVALID_AMOUNT: "Ghalat raaqm",
    COLLECTION_DATE: "Jamah karny ki tareekh",
    YOUR_DATA_IS_100_SAFE_AND_SECURE: "Apka data 100% bah hifazat aur mehfooz hai",
    TRANSACTION_HAS_BEEN_UPDATED: "lien dien update hogai",
    MORE: "Mazeed",
    PROMOTE: "Ishtiharaat",
    ADVERTISE_YOUR_PRODUCTS: "Apne products ka ishtehar",
    ADVERTISE_YOUR_PRODUCTS_DESC: "Aaj ki khususi promotion ka apne sarifeen ko btaien Whatsapp ky zariyay!",
    CREATE_A_NEW_AD: "Naya Ishtehar bnaien",
    STEP_1: "Pehla Qadam",
    TAKE_OR_UPLOAD_A_PICTURE_OF_THE_PRODUCT: "Jis product ko ap promote krna chahty hain Oski tasveer lien ya upload karien aur caption likhien",
    TAK_UPLOAD_A_PICTURE: "Tasveer lien / upload",
    CREATE_A_NEW_AD_POST_TO_SHARE: "Share krny ky liey ek nae ishtehari post bnaien",
    THIS_IS_WHATSAPP_TEXT_MESSAGE: "Yeh whatsapp text message hai",
    STEP_2: "Dosra Qadam",
    DO_YOU_WANT_TO_SEND_AN_ACCOMPANYING_MESSAGE: "Kia ap sath mai ek pegham bhejna chahty hain?",
    PENDING: "zery ghor",
    UPCOMING: "Aiendah",
    COLLECTION_DATE_NOT_SET: "Jamah karny ki tareekh muqrar nahi hai",
    SELECT_DATE: "Tareekh muntakhib karien",
    SEND_REMINDER: "Yadihani bhejien",
    SELECT_ON_MAP: "Naqshey per muntakhib karien",
    LOCATION_UPDATED: "jagha update hogai",
    WELCOME_TO_CREDITBOOK: "Creditbook mai khush amdeed",
    MANAGE_FINANCES_TRACK_PAYMENTS: "Maal ko manage karien. Adaeygeh track karien. Update receivable. Ab udhaar hogaya digital!",
    LETS_GO: "Chalo!",
    KHATA_AB_PHONE_PE: "Khaata ab phone pe!",
    STAY_INFORMED_AND_BE_IN_CONTROL_OF_CREDIT: "mutalah rahein aur Sarifeen aur suppliers ky sath credit ki lien dien nazar rakhien.",
    YOUR_DATA_IS_SAFE_AND_SECURED: "Apka data ba hifazat aur mehfooz hai",
    TEXT_ABOUT_CLOUD_SECURITY_GOES_HERE: "Cloud secuirty ky bare mai peghaam yahan aur yahan aty hain",
    ASAAN_HISAAB_KITAAB_ACCOUNTS: "Assan hisaab kitaab accounts mintues mai. Dheer sare papers se ghuzarna aur sarifeen se behes karna ab aur nhi.",
    SKIP: "Chordien",
    TITLE: "Title",
    ADD_YOUR_CAPTION_HERE: "Caption yahan lilkhien",
    DONE: "Hogya",
    AD_INSERTED_SUCCESS: "Ishteehar lag gya!",
    ONLINE_NOTE: "Aap Online Mode mai App istemaal kr rahy hain!",
    OFFLINE_NOTE: "Aap Offline Mode mai App istemaal kr rahy hain!",
    TITLE_IS_REQUIRED: "Title zarori hai!",
    AD_UPDATED_SUCCESS: "Ishteehar updated!",
    CAROUSEL_DESC: "Rozanah apne qarobari account ko update krky apna time bachaey. Customer se behes aur papers mai time zaya krna ab bilkul nhi.",
    EXISTING_CUSTOMER: "Mojudah customer",
    ADD_A_STARTING_BALANCE: "Ibtadai balance dalien",
    STARTING_BALANCE: "Ibtadai balance",
    REFERRALS: "Referrals",
    REFERRAL_CODE: "Referral Code",
    SHARE_NOW: "Share abhi",
    TOTAL_REFERRALS: "Kull Referrals",
    ACTIVE_REFERRALS: "Active Referrals",
    CB_POINTS: "CB Points",
    INVITE_YOUR_FRIENDS_AND_EARN_CB_POINTS: "Apne doston ko dawat dien aur CreditBook points hasil karien",
    ACTIVE: "Active",
    INACTIVE: "Inactive",
    REFERRAL_SHARE_TEXT: "Ajj muft mai Credit Book download karien is link per click krky aur apne karobar kko tabdeel karien jesa mainy kia hai apni sari lien dien jesy naqdi farokth ya udhar aur bhi bohot sary features mojood hain backups aur data security ky sath!",
    ENJOYING_THE_APP_WHY_NOT_GIVE_IT_5_STARS: "App se lutfandoz ho rahy hain. kyun nah 5 star dien!",
    FREE_SMS_LIMIT_REACHED: "Aap ky rozanah ki SMS ki had is customer ky liey tajawuz kar gai hai",
    LOGIN_WITH_TOUCHID: "TouchID ky zariyay login",
    ENABLE_TOUCHID: "TouchID enable",
    DISABLE_TOUCHID: "TouchID disable",
    OTP_WILL_BE_SEND_ON: "OTP bheja jaeyga yahan",
    CHOOSE_YOUR_BUSINESS_CATEGORY: "Apne karobar ki category chune",
    CHOOSE_TYPE: "Iqsaam chune",
    CHOSE_CATEGORY: "Category chune",
    CREATE_BUSINESS: "Karobar bnaey",
    BUSINESS_SETTING: "Karobar setting",
    UPDATE_BUSINESS: "Update karobar",
    FINGER_PRINT_COMPATIBLE_ERROR: "Aap ki device finger print ky liey compatible nhi hai",
    VERIFY_FINGER_PRINT: "Finger print tasdeeq",
    FINGER_PRINT_VERIFIED_SUCCESSFULLY: "Finger print qamiyabi ky sath tasdeeq",
    TRANSACTIONS_TAGS: "Lain deen ke tag",
    CASH_IN_HAND: "Haath mein cash",
    UDHAAR: "Udhaar",
    EXPENSES: "Akhrajaat",
    PAYMENT: "Adaigi",
    REPAYMENT: "Adaigi",
    CASH_SALE: "Naqad farokht",
    STOCK_ON_CREDIT: "Udhaar par maal",
    OTHER: "Deegar",
    SEND_FREE_SMS_TO_CUSTOMER: "Customer ko muft sms bhejen",
    SEND_FREE_SMS: "Muft sms bhejen",
    NOT_NOW: "abhi nahi",
    TRANSACTION_NOTE_WITH_MSG: "Note ke sath lain deen ka pegham",
    SELECT_BUSINESS: "Business muntakhib karen",
    SMS_SENDING_FAILED: "SMS bhejna nakaam hogaya barah karam apna internet check karen",
    CREATE_NEW_TAG: "Naya tag banayen",
    CUSTOMER_WEB_APP: "Customer web app",
    HEY_WELCOME_TO_CREDIT_BOOK: "CreditBook mein khush aamdeed",
    HEY_WELCOME_TO_CREDIT_BOOK_CUSTOMER_WEB_APP: "CreditBook ki customer web app kishn mein khush aamdeed",
    SELECT_YOUR_BUSINESS_CATEGORY: "Apne kaarobar ki qisam ka intikhab karen",
    TRADER: "Tajir",
    PERSONAL_BOOKKEEPING: "Zaati khatah",
    CONTRACTOR: "Thaikaydaar",
    CAR_AUTOMOBILE: "Car, automobile aur workshop",
    BAKERY_SWEETS: "Bakery / Mithayyan",
    COSMETICS: "Make up",
    CLOTHES_FABRIC: "Kapray aur suit",
    ELECTRONICS: "Electronics",
    HARDWARE_SANITARY: "Hardware aur sanitary",
    COMPUTER: "Computer",
    GOLD_JEWELERY: "Sona aur zewraat",
    LOGISTICS_TRANSPORT: "Logistics aur transport",
    MEDICAL_PHARMACY: "Medical aur Pharmacy",
    OIL_CHEMICALS: "Tail / chemical",
    PAN_KHOKHA: "Paan / khokha",
    PHOTO_PRINTING: "Photo / printing",
    POULTRY: "Poultry",
    DAIRY: "Doodh ki dokaan / barah",
    EDUCATION_SCHOOL: "Taleem o tarbiyat aur school",
    SHOES: "Jootay",
    SPORTS: "Khail / ka samaan",
    STATIONARY: "Stationary",
    KIRYANA: "Kiryana",
    MOBILE_AND_EASYLOAD: "Mobile aur EasyLoad",
    ALUMINIUM_STEEL_GLASS: "Aluminium, Steel aur glass",
    RESTAURANT_HOTEL: "Dhaba / Hotel",
    PICK_AND_DROP_TAXI_SERVICE: "Pick and drop ya Taxi Service",
    BOOKS: "Kitaben",
    TRAVEL: "Travel",
    GARMENTS_AND_TAILOR: "Garments aur Tailor Shop",
    BUSINESS_CATEGORY: "Karobar ki qisam",
    BUSINESS_STATEMENT: "Karobari report",
    BUSINESS_LOCATION: "Karobar jagha",
    WHOLESALER: "Wholesaler",
    RETAILER: "Retailer",
    DISTRIBUTOR: "Distributor",
    SALES_AGENT: "Sales Agent",
    CONSTRUCTION: "Tameerat",
    GENERAL_STORE: "General store",
    CREATE_NEW_BUSINESS: "Naya karobar bnaey",
    CANCEL: "Cancel",
    CHOOSE_YOUR_BUSINESS_TYPE: "Karobar ki iqsaam chuney",
    GET_MY_LOCATION: "Meri location",








































}