import React, { useState, useEffect, useRef } from "react";
import { useDispatch,  } from "react-redux";

 
import useStyles from "./styles";
 
import DebitMessage from "./DebitMessage";
import NotificationMessage from "./NotificationMessage";

export const Transaction = ({ history, item, account ,onClick }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

 


  switch (item.sub_type) {
    case "notification":
      return <NotificationMessage />;

    // <== Add additional transaction types here.
    // Transaction Starting Balance
    default:
      return (
        <>
          <DebitMessage item={item} onClick={onClick} />
        </>
      );
  }
};

export default Transaction;
