import { makeStyles } from "@material-ui/core/styles";
import { themeStyleSheet, themeColors } from "../../../constants";
const useStyles = makeStyles((theme) => ({
  container: {
    background: "white",
    padding: 0,
    margin: 0,

  },
  phantomStyle: {
    display: "block",
    [`${theme.breakpoints.down("sm")} and (orientation: landscape)`]: {
      height: "50vh",
    },
    [`${theme.breakpoints.up("lg")}`]: {
      height: "calc( 20vh )",
    },
    height: "calc( 64px)",
    minHeight: "60px",
    width: "100%",
  },
  leftCont: {
    [`${theme.breakpoints.down("sm")}`]: {
      borderRight: "0px",
      position: "fixed",
      backgroundColor: "white",
      zIndex: 100
    },


    borderRight: "1px solid rgba(0, 0, 0, 0.12)",

    paddingBottom: "0px",
    overflow: "hidden",
  },
  rightCont: {
    height: "100vh",
    overflowY: "auto",
    overflowX: "hidden",
  },
  customerListContainer: {
    [`${theme.breakpoints.up("xl")}`]: {
      height: "calc( 80vh )",
    },
    padding: "0px 10px",
    height: "calc(80vh - 64px)",
  },
  balanceCont: {
    display:"flex",
    flexDirection:"row",
    alignContent:"center",
    alignItems:"center",
    [`${theme.breakpoints.up("sm")} `]: {
      width:"100%",
      justifyContent:"center",
  },
  },
  balanceBox: {
    padding: "10px 10px",
    marginRight: "5px", 
    width:"fit-content",
    [`${theme.breakpoints.up("sm")} `]: {
        width:"100%"
    },
  },
 
  currentBalTag:{
    fontWeight: "bold",
    fontSize:"14px",
    color: props => props?.current_balance < 0 ? themeColors.amountDue : props?.current_balance > 0 ? themeColors.amountPay : themeColors.amountClear,
    marginRight:"2px"
  },
  currentBalAmount:{
    fontSize:"18px"
  },

  customersCont:{
    padding: "10px 10px",
    marginRight: "5px", 
    width:"fit-content"
  },
  customerCountText:{
    fontSize:"18px",
    fontWeight: "bold",
  },
  customersTitleText: {
    fontWeight: "bold",
    fontSize: "0.9rem",
    color: props => props?.current_balance < 0 ? themeColors.amountDue : props?.current_balance > 0 ? themeColors.amountPay : themeColors.amountClear
  },
  balanceTitleText: {
    fontSize: "0.8rem",
    color: "rgba(0,0,0,0.7)",
  },
  reportCont:{
    borderLeft:"1px solid rgba(0,0,0,0.5)",
    height:"40px",
    marginTop:"10px"
 
  },
  reportsSectionCont:{
    padding: "10px 10px",
    marginRight: "5px", 
    width:"fit-content",
    display:"flex",
    flexDirection:"row",
    alignContent:"center",
    height:"100%",
    alignItems:"center",
    justifyContent:"flex-start",

  },
  reportIcon:{
    fontSize: 18,
    marginRight: "5px",
    color: 'rgba(0,0,0,0.8)',
  },
  reportTitleText:{
    fontSize: 14,
    color: 'rgba(0,0,0,0.7)',
    textDecorationLine: 'underline'
  },

  searchCont: {
    height: "max-content",
    padding: "0px 10px",
  },
  fab: {
    background: themeStyleSheet.appColor,
    position: "fixed",
    bottom: "11vh",
    right: theme.spacing(1),
    zIndex: theme.zIndex.drawer + 2,

    [`${theme.breakpoints.down("sm")}`]: {
      "&:hover": {
        background: themeStyleSheet.appColor,
      },
    },
  },
  fabIcon: {
    color: "white",
  },

  balanceContainer: {
    margin: 0,
 
    paddingBottom: "0px"
  },
  addCustomerCont: {
    position: "fixed",
    bottom: 0,
    borderRight: "1px solid rgba(0, 0, 0, 0.12)",
    borderTop: "1px solid rgba(0, 0, 0, 0.12)",
    minHeight: "60px",
    [`${theme.breakpoints.up("lg")}`]: {
      minHeight: "60px",
    },

    backgroundColor: "white",
    left: 0,
  },
  addCustomerButton: {
    backgroundColor: themeColors.appColor,
    "&:hover": {
      backgroundColor: "#353030",
    },
  },
  thumbStyle: {
    borderRadius: 6,
    backgroundColor: themeColors.appColor,
    width: "0.15rem",
    margin: "0px",
    padding: "0px",
  },
}));

export default useStyles;
