import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useHistory,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { auth, _USERS_REF } from "../constants";
import Login from "../components/container/Login";
import Verification from "../components/container/Verification";
import { getIsAuthenticated, getLoggedInUser } from "../store/selectors";
import UserMiddleware from "../store/middleware/User";
import PrivateRoute from "./PrivateRoutes";
import Dashboard from "../components/container/Dashboard";
import AddAccount from "../components/container/AddAccount";

import Welcome from "../components/container/Welcome";
import Loading from "../components/container/Loading";
import NotFound from "../components/container/NotFound";
import CreateTransaction from "../components/container/CreateTransaction";
import { CreateProfile } from "../components/container/CreateProfile";
import UserStatement from "../components/container/UserStatement";
const Routes = () => {
  
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Loading} />
        <Route exact path="/welcome" component={Welcome} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/verify" component={Verification} />
        <PrivateRoute exact path="/dashboard" component={Dashboard} />
        <PrivateRoute exact path="/addCustomer" component={AddAccount} />
        <PrivateRoute exact path="/createTransaction" component={CreateTransaction} />
        <PrivateRoute exact path="/createProfile" component={CreateProfile} />
        <PrivateRoute exact path = "/userStatement" component={UserStatement} />
        <PrivateRoute exact path="*"  component={NotFound} />
      </Switch>
    </Router>
  );
};

export default Routes;
