//    Collection: Transaction        //    

// export var transaction = {
//     "account_id": "",            // Account Id from Accounts Collection,
//     "account_name": "",
//     "user_id": "",              // User Id from Users Collection,
//     "creation_timestamp": "",       // Transaction creation timestamp with Date
//     "transaction_timestamp": "",       // Transaction creation timestamp with Date
//     "is_credit": false,          // If Udhaar then 'true' else 'false' 
//     "transaction_image": "",
//     "note": "",
//     "sms_status": false,
//     "amount": "",
//     "type": "default",  // default,deleted...
//     "balance": ""



// }


export var transaction = {
    "user_id": "",                                    // User Id from Users Collection,
    "creation_timestamp": "",                        // Transaction creation timestamp with Date
    "transaction_timestamp": "",
    "note": "",
    "amount": "",
    "type": "default",                               // default,deleted...
    "transaction_image": "",
    "user_name": "",
    "user_business_name": "",
    "payment_return_date": "",
    "send_auto_reminder": false,

    "corresponding_transaction_id": null,            //                                             ##### ADDED #####

    "transaction_type": null,                       // credit or debit                              ##### RENAMED FROM 'is_credit' ####  
    "customer_id": "",                              // Account Id from Accounts Collection,         ##### RENAMED FROM 'account_id' ####
    "customer_name": "",                            //                                              ##### RENAMED FROM 'account_name' ####
    "customer_net_balance_after_transaction": 0,    //                                              ##### RENAMED FROM 'balance' ####
    "customer_mobile_no": null,                     //                                              ##### RENAMED FROM 'account_mobile_no' ####
    "deleted_via_customer_deletion": false,//                                              ##### DELETED #####
    // "sms_status": false,             
    "voice_note":{
        "url":"",
        "duration":""
    }            

}