import React, { useState, useMemo } from "react";
import { useDispatch } from "react-redux";

import {
  Grid,
  Icon,
  Typography,
  Avatar,
  IconButton,
  ButtonBase,
} from "@material-ui/core";

import useStyles from "./styles";
import { strings } from "../../../i18n";

export const AccountHeader = ({
  history,
  title,
  subTitle,
  titleImgSrc,
  titleImgFunc,
  leftIcon,
  leftIconFunc,
  rightIcon,
  rightIconFunc,
  forceShowLeftIcon,
  showAvatar
}) => {
  const classes = useStyles({ subTitle ,forceShowLeftIcon });
  const dispatch = useDispatch();
  const getHeaderImgSrc = useMemo(() => {
    let imgSrc = "";
    if (titleImgSrc)
      imgSrc = {
        uri: titleImgSrc.includes("firebasestorage") ? titleImgSrc : "",
      };
    else imgSrc = require("../../../assets/profile-placeholder.jpg");
    return imgSrc;
  });
  return (
    <>
      <Grid
        direction="row"
        container
        justify="flex-start"
        alignItems="center"
        className={classes.headerMainContainer}
      >
        <IconButton className={classes.leftIconCont} onClick={leftIconFunc}>
          <Icon className={classes.leftIcon}>{leftIcon}</Icon>
        </IconButton>

        <ButtonBase onClick={titleImgFunc} className={classes.profileContainer}>
         { showAvatar && <Avatar className={classes.headerImg} src={titleImgSrc}></Avatar> }
          <div className={classes.titleTextContainer}>
            <Typography align="left" className={classes.titleText}>
              {title}
            </Typography>
            {subTitle && (
              <Typography align="left" className={classes.subTitleText}>
                {subTitle}
              </Typography>
            )}
          </div>
        </ButtonBase>
        {rightIcon && (
          <IconButton className={classes.rightIconCont} onClick={rightIconFunc}>
            <Icon className={classes.rightIcon}>{rightIcon}</Icon>
          </IconButton>
        )}
      </Grid>
    </>
  );
};

export default AccountHeader;
