import React from "react";
import { useDispatch } from "react-redux";
import {
  Grid,
  Typography,
  Snackbar,
} from "@material-ui/core";
import { Alert } from '@material-ui/lab';
import { seed } from "../../../utils";
import { useState, useEffect } from "react";
import { userSchema } from "../../../firebase/schema/user";
import OtpInput from "react-otp-input";
import useStyles from "./styles";
import OnBoardingFooter from "../../common/OnBoardingFooter";
import Counter from "../../common/Counter";

import { auth, _USERS_REF } from "../../../constants";
import UserMiddleware from "../../../store/middleware/User";
import Loader from "../../common/Loader";
import { strings } from "../../../i18n";

export const Verification = ({ history }) => {
  const styles = useStyles();
  const { mobileNumber } = history.location.state;
  const [open, setOpen] = useState(false);

  const [verificationCodeSeed, setVerificationCodeSeed] = useState(
    history.location.state.verificationCodeSeed
  );
  const [verifCode, setVerifCode] = useState("");
  const [count, setCount] = useState(60);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    if (verifCode && verifCode.length === 4) confirmCode();
  }, [verifCode]);

  let unsubscribe = null;
  useEffect(() => {
    unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {

        user = user.toJSON();
        console.log("user onAuthStateChanged toJSON : ", user);
        let docRef = _USERS_REF.doc(user.uid);
        let doc = await docRef.get();
        if (doc.exists) {
          let docData = doc.data();
          dispatch(UserMiddleware.getUser(user.uid));
          let userData = {
            ...doc.data(),
          };
          await _USERS_REF.doc(user.uid).update({
            contextID:"fake",
            user_last_activity: new Date(),
            referral_code: docData?.referral_code || docData?.id?.split("-")[0],
          });
          if (userData.is_active) {
            history.replace("/");
          } else {
            history.replace("/createProfile");
          }
        } else {
          console.log("No such document!");
          console.log("Creating new user..........");
          let referral_code = user.uid.split("-")[0];
          let userData = {
            ...userSchema,
            mobile_no: mobileNumber,
            id: user.uid,
            user_signup_date: new Date(),
            referral_code,
            contextID: 'fake'
          };
          await _USERS_REF.doc(user.uid).set(userData);
          dispatch(UserMiddleware.getUser(user.uid));
          console.log("user created !!!   ");

          history.replace("/");
        }
      }
      setLoading(false);
    });
    return () => {
      unsubscribe && unsubscribe();
    };
  }, []);

  const confirmCode = async () => {
   

    if (!mobileNumber) {
      console.error("Invalid mobile no")
      return;
    }

    let otpSeedFromUser = seed(verifCode);
    UserMiddleware.verifyLogin(mobileNumber, otpSeedFromUser)
    .then((res) => {
      setLoading(true);
      let { customToken } = res;
      try {
        auth.signInWithCustomToken(customToken)
          .catch(function (error) {
            setVerifCode("");
            setLoading(false);
            console.log(error);
          });
      } catch (err) {
        setVerifCode("");
        setLoading(false);
        console.error(err);
      }
    })
    .catch((error) => {
      console.error(error);
      setOpen(true);
      setVerifCode("");
      setLoading(false);
    });

  };

  const resendCode = () => {
    setLoading(true);
    UserMiddleware.resendVerificationCode(mobileNumber)
      .then((res) => {
        setLoading(false);
        setVerificationCodeSeed(res.seed);
        setCount(60);
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  return (
    <>
      <Loader loading={loading} />
      <Grid
        style={{ minHeight: "90vh" }}
        direction="row"
        container
        alignItems="center"
        justify="center"
      >
        <Grid>
          <Typography align="center" className={styles.verificationCodeHeading}>
            {strings("VERIFICATION_CODE")}
          </Typography>
          <Grid container alignItems="center" justify="center" direction="row">
            <Typography className={styles.verificationTextCont}>
              {strings("VERIFICATION_CODE_MSG")} {mobileNumber}
            </Typography>
          </Grid>
          <OtpInput
            containerStyle={{ justifyContent: "center" }}
            inputStyle={styles.otpInput}
            value={verifCode}
            onChange={(otp) => setVerifCode(otp)}
            numInputs={4}
            shouldAutoFocus
            isInputNum={true}
          />
          <Counter
            count={count}
            setCount={setCount}
            resendCode={() => resendCode()}
          />
        </Grid>
      </Grid>

      <OnBoardingFooter
        leftText={strings("BACK")}
        leftFunc={() => {
          history.goBack();
        }}
        rightText={strings("NEXT")}
        rightFunc={() => {
          confirmCode();
        }}
      />
      <Snackbar   open={open}  onClose={() => setOpen(false)}  >
    
          <Alert onClose={ ()=>setOpen(false)} severity="error">
          Invalid code!
          </Alert>
      </Snackbar >

    </>
  );
};

export default Verification;
