import UserStatementAction from "../actions/UserStatement"
import { TRANSACIONS_REF, _TRANSACTIONS_REF, _CUSTOMERS_REF } from "../../constants";
import moment from "moment";


export default class UserStatementMiddleware {
    static getUserTransactions(pageNo) {
        console.log("getUserTransactions()")
        return (dispatch, getState) => {
            return new Promise((resolve, reject) => {
                let { user } = getState();
                let userTransactions = [];
                let dateTag = "";


                let query = _TRANSACTIONS_REF.where("user_id", "==", user.id).where("type", "==", "default").orderBy("creation_timestamp", "desc").limit(pageNo * 10)  // comment this because no pagination for now
                // let query = TRANSACIONS_REF.where("user_id", "==", user.id).orderBy("creation_timestamp", "desc")

                query.get().then(async transactions => {
                    console.log("transactions : ", transactions)
                    for (let doc of transactions.docs) {
                        let docData = doc.data();
                        if (doc.metadata.fromCache) {
                            try {
                                let customer = await _CUSTOMERS_REF.doc(docData.customer_id).get();
                                customer = customer.data();
                                if (customer.type === 'deleted') {
                                    continue;
                                }
                            } catch (err) {
                                console.log("inside catch !! ", err)
                            }
                        }
                        if (docData.sub_type === 'notification') {
                            continue;
                        }

                        let timestamp = new Date(docData.creation_timestamp.toDate())
                        dateTag = moment(timestamp.getTime()).calendar(null, {
                            sameDay: '[Today]',
                            nextDay: '[Tomorrow]',
                            nextWeek: 'dddd',
                            lastDay: '[Yesterday]',
                            // lastWeek: '[Last] dddd',
                            lastWeek: 'D MMMM YYYY',
                            sameElse: 'D MMMM YYYY'
                        })


                        userTransactions.push({
                            ...docData,
                            id: doc.id,
                            // is_synced: !doc.metadata.hasPendingWrites,
                            dateTag
                        });

                    }
                    // console.log("userTransactions : ", userTransactions)
                    dispatch(UserStatementAction.getUserTransaction(userTransactions))
                    resolve(userTransactions)
                })
            })
        }

    }

    static getUserAllTransactions() {
        console.log("getUserTransactions()")
        return (dispatch, getState) => {
            return new Promise((resolve, reject) => {
                let { user } = getState();
                let userTransactions = [];
                let dateTag = "";


                let query = _TRANSACTIONS_REF.where("user_id", "==", user.id).where("type", "==", "default").orderBy("transaction_timestamp", "desc")  // comment this because no pagination for now
    

                query.get().then(async transactions => {
                    console.log("transactions : ", transactions)
                    for (let doc of transactions.docs) {

                        let docData = doc.data();
                        if (doc.metadata.fromCache) {
                            try {
                                let customer = await _CUSTOMERS_REF.doc(docData.customer_id).get();
                                customer = customer.data();
                                if (customer.type === 'deleted') {
                                    continue;
                                }
                            } catch (err) {
                                console.log("inside catch !! ", err)
                            }
                        }
                        if (docData.sub_type === 'notification') {
                            continue;
                        }

                        let timestamp = new Date(docData.creation_timestamp.toDate())
                        dateTag = moment(timestamp.getTime()).calendar(null, {
                            sameDay: '[Today]',
                            nextDay: '[Tomorrow]',
                            nextWeek: 'dddd',
                            lastDay: '[Yesterday]',
                            // lastWeek: '[Last] dddd',
                            lastWeek: 'D MMMM YYYY',
                            sameElse: 'D MMMM YYYY'
                        })


                        userTransactions.push({
                            ...docData,
                            id: doc.id,
                            // is_synced: !doc.metadata.hasPendingWrites,
                            dateTag
                        });

                    }
                    // console.log("userTransactions : ", userTransactions)
                    // dispatch(UserStatementAction.getUserTransaction(userTransactions))
                    resolve(userTransactions)
                })
            })
        }

    }

    static getUserStatement(startDate, endDate) {
        console.log("getUserStatement : ", startDate, endDate)
        return (dispatch, getState) => {
            return new Promise((resolve, reject) => {
                let { user } = getState();
                let userTransactions = [];
                let dateTag = "";
                let query = "";
                if (startDate && endDate) {
                    query = _TRANSACTIONS_REF.where("user_id", "==", user.id).where("type", "==", "default").where("transaction_timestamp", ">=", new Date(startDate)).where("transaction_timestamp", "<=", new Date(endDate)).orderBy("transaction_timestamp", "desc")  // comment this because no pagination for now
                }
                else {
                    query = _TRANSACTIONS_REF.where("user_id", "==", user.id).where("type", "==", "default").orderBy("transaction_timestamp", "desc")  // comment this because no pagination for now
                }
                query.get().then(async transactions => {
                    let amount_of_total_credits = 0;
                    let amount_of_total_debits = 0;
                    let no_of_credits = 0;
                    let no_of_debits = 0;
                    // console.log("transactions : ", transactions)
                    for (let doc of transactions.docs) {

                        let docData = doc.data();
                        if (doc.metadata.fromCache) {
                            try {
                                let customer = await _CUSTOMERS_REF.doc(docData.customer_id).get();
                                customer = customer.data();
                                if (customer.type === 'deleted') {
                                    continue;
                                }
                            } catch (err) {
                                console.log("inside catch !! ", err)
                            }
                        }
                        if (docData.sub_type === 'notification') {
                            continue;
                        }

                        // let timestamp = new Date(docData.creation_timestamp.toDate())
                        // dateTag = moment(timestamp.getTime()).calendar(null, {
                        //     sameDay: '[Today]',
                        //     nextDay: '[Tomorrow]',
                        //     nextWeek: 'dddd',
                        //     lastDay: '[Yesterday]',
                        //     // lastWeek: '[Last] dddd',
                        //     lastWeek: 'D MMMM YYYY',
                        //     sameElse: 'D MMMM YYYY'
                        // })


                        userTransactions.push({
                            ...docData,
                            id: doc.id,
                            // is_synced: !doc.metadata.hasPendingWrites,
                            // dateTag
                        });


                    }
                    for (let i = 0; i < userTransactions.length; i++) {
                        if (userTransactions[i].transaction_type === 'debit') {
                            amount_of_total_debits += Number(userTransactions[i].amount);
                            no_of_debits++;
                        }
                        else {
                            amount_of_total_credits += Number(userTransactions[i].amount);
                            no_of_credits++;
                        }
                    }
                    let current_balance = amount_of_total_credits - amount_of_total_debits;
                    console.log(amount_of_total_credits, amount_of_total_debits)
                    console.log("userTransactions : ", userTransactions)
                    dispatch(UserStatementAction.getUserTransaction(userTransactions))
                    resolve({ amount_of_total_credits, amount_of_total_debits, userTransactions, no_of_debits, no_of_credits, current_balance })
                })
            })
        }

    }
}
