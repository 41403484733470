import { TRANSACTION } from "../actions/types";
import INITIAL_STATE from "./initialState";



export var transactionsReducer = (state = INITIAL_STATE.transactions, action) => {
    switch (action.type) {
        case TRANSACTION.GET_TRANSACTIONS:
            return action.value;

        default:
            return state;
    }
}

