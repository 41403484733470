import { Button } from "@material-ui/core";
import React, { memo } from "react";

import { Grid } from "@material-ui/core";
import useStyles from "./styles";

const OnBoardingFooter = memo(
  ({ leftFunc, rightFunc, rightText, leftText }) => {
    const styles = useStyles();
    return (
      <>
        <div className={styles.phantomStyle} />
        <Grid
          container
          alignItems="center"
          direction="row"
          justify={leftText ? "space-between" : "flex-end"}
          className={styles.footerStyle}
        >
          {leftText && (
            <Grid alignItems="center" container item xs={3} justify="center">
              <Button
                variant="contained"
                className={styles.leftButton}
                onClick={leftFunc}
              >
                {leftText}
              </Button>
            </Grid>
          )}
          {rightText && (
            <Grid alignItems="center" container item xs={3} justify="center">
              <Button variant="contained" color="primary" onClick={rightFunc}>
                {rightText}
              </Button>
            </Grid>
          )}
        </Grid>
      </>
    );
  }
);

export default OnBoardingFooter;
