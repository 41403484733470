import { makeStyles } from "@material-ui/core/styles";
import { themeStyleSheet, themeColors } from "../../../constants";
const useStyles = makeStyles({
  parentDisable: {
    position: "fixed",
    top: 0,
    left: 0,
    background: "rgba(0,0,0,.1)",
    "z-index": 998,
    height: "100%",
    width: "100%",
  },

  overlayBox: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform:"translate(-50%, -50%)",
    "z-index": "1000",
  },
});

export default useStyles;
