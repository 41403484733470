import { makeStyles } from '@material-ui/core/styles';
import { themeStyleSheet, themeColors } from "../../../constants";
const useStyles = makeStyles({
    footerStyle: {
        backgroundColor: '#F2F2F2',
        fontSize: "20px",
        position: "fixed",
        left: 0,
        bottom: 0,
        height: "10vh",
        width: "100%"
    },
    phantomStyle: {
        display: "block",
        height: "10vh",
        width: "100%"
    },
    leftButton:{
        backgroundColor: "rgba(0,0,0,0.03)"
    }
});

export default useStyles;
