import React from "react";
import { useDispatch } from "react-redux";

import {
  Grid,
  Typography,
  Avatar,
  Paper,
  IconButton,
  Icon,
} from "@material-ui/core";

import useStyles from "./styles";
import { formatNum } from "../../../../../utils";

export const ProfileItem = ({ history, toggleDrawer, user }) => {
  const classes = useStyles({ current_balance: user?.current_balance });
  const dispatch = useDispatch();
  return (
    <Paper className={classes.drawerTopCont} elevation={4} square={true}>
      <Grid container>
        <IconButton onClick={toggleDrawer} className={classes.toggleIcon}>
          <Icon>chevron_left</Icon>
        </IconButton>

        <Avatar className={classes.userAvatar} />

        <Grid item className={classes.userNameContainer}>
          <Typography className={classes.textColor} align="left" variant="h6">
            {user?.business_name  || user?.name}
          </Typography>
          <Typography
            align="center"
            className={classes.textColor}
            variant="body2"
          >
            {user.mobile_no}
          </Typography>
        </Grid>
        <Grid container justify="center" alignItems="center">
          <Typography
            align="center"
            className={classes.balanceText}
            variant="body2"
          >
            Rs {user && formatNum(Math.abs(user.current_balance))}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ProfileItem;
