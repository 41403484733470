//    Collection: Users        //    
// export var userSchema = {
//   "name": "",
//   "mobile_no": "",
//   "email": "",
//   "business_name": "",
//   "businesss_type": null,
//   "img_base_64": "",
//   "location": "",
//   "profile_image": "",
//   "loan_distributed": 0,
//   "is_active": false,                              //   If Login from Fb or Google
//   "latest_otp": '1234',                           // 3 latest OTPS
//   "accounts": [                                   // Array of Account IDS From Account collection

//   ],
//   "no_of_accounts": 0,
//   "no_of_payments": 0,
//   "no_of_credits": 0,
//   "payments_total": 0,
//   "credits_total": 0
// }

export var userSchema = {
  "name": "",
  "mobile_no": "",
  "email": "",
  "business_name": "",
  "businesss_type": null,
  "img_base_64": "",
  "is_active": false,                       // True if User already entered his personsal info

  "location_past_logs": null,               // Will use in future                                   ##### ADDED #####
  "location_our_logs": null,                // Will use in future                                   ##### ADDED #####
  "no_of_transactions": 0,                  // no_of_debits + no_of_credits                         ##### ADDED #####
  "amount_of_total_transactions": 0,        //  |amount_of_credits| + |amount_of_debits|            ##### ADDED #####
  "user_signup_date": null,                 //                                                      ##### ADDED #####
  "user_last_activity": null,               //                                                      ##### ADDED #####

  "no_of_credits": 0,                       // Green (advance) transaction                          ##### RENAMED FROM 'no_of_payments' ####
  "amount_of_credits": 0,                   // Total amount of Green (advance) transactions         ##### RENAMED FROM 'payments_total' ####
  "no_of_debits": 0,                        // Red (udhaar) transaction                             ##### RENAMED FROM 'no_of_credits' ####
  "amount_of_debits": 0,                    // Total amount of Red (udhaar) transactions            ##### RENAMED FROM 'credits_total' ####
  "current_balance": 0,                     //  amount_of_credits + amount_of_debits                ##### RENAMED FROM 'loan_distributed' ####
  "no_of_customers": 0,                     //                                                      ##### RENAMED FROM 'no_of_accounts' ####
  "img_url": "",                            // firebase storage url or local path                   ##### RENAMED FROM 'profile_image' ####
  "fcm_token": null,                         //                                                      ##### RENAMED FROM 'fcmToken' ####
  "referral_code": null,
  "referrer_code": null,
  "total_referrals": 0,
  "active_referrals": 0,
  "cb_points": 0,
  "referred_users": []
  // "accounts": []                          // Array of Account IDS From Account collection        ##### DELETED #####
  // "location": "",                        //                                                      ##### DELETED #####
  // "latest_otp": '1234',                  //                                                      ##### DELETED #####
}