import {
  BottomNavigation,
  BottomNavigationAction,
  Icon,
} from "@material-ui/core";
import React, { memo, useEffect, useState } from "react";

import { Grid } from "@material-ui/core";
import useStyles from "./styles";
import { useHistory } from "react-router";
import { strings } from "../../../i18n";

const BottomTab = memo(({   }) => {
  const styles = useStyles();
  const [navState, setNavState] = useState("home");
  const history = useHistory()

  const onChange = (e, value) => {
    // navigate to value
    setNavState(value);
   
   // history.push(value);
 
  };
  return (
    <>
      <div className={styles.phantomStyle} />

      <Grid
        container
        alignItems="center"
        direction="row"
        justify="space-between"
        className={styles.footerStyle}
      >
        <BottomNavigation
          value={navState}
          onChange={onChange}
          className={styles.tabStyle}
          showLabels={true}
        >
          <BottomNavigationAction
            classes={{ root: styles.root, selected: styles.selected }}
            label={strings("HOME")}
            value="home"
            icon={<Icon>home</Icon>}
          />

      

          <BottomNavigationAction
            classes={{ root: styles.root, selected: styles.selected }}
            label={strings("MORE")}
            value="more"
            icon={<Icon>more_horiz</Icon>}
          />
        </BottomNavigation>
      </Grid>
    </>
  );
});

export default BottomTab;
