import React, { useState, useMemo } from "react";
import { useDispatch } from "react-redux";

import {
  Grid,
  Icon,
  Typography,
 
  IconButton,
  ButtonBase,
} from "@material-ui/core";

import useStyles from "./styles";
 

export const DrawerHeader = ({
 
  title,
 
  leftIcon,
  leftIconFunc
 
 
}) => {
  const classes = useStyles( );
 
   
  return (
    <>
      <Grid
        direction="row"
        container
        justify="flex-start"
        alignItems="center"
        className={classes.headerMainContainer}
      >
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          className={classes.menuButton}
          onClick={ leftIconFunc}
        >
          <Icon className={classes.leftIcon}>{leftIcon}</Icon>
        </IconButton>
        <ButtonBase   >
          <div className={classes.titleTextContainer}>
            <Typography align="left" className={classes.titleText}>
            {title}
            </Typography>
          </div>
        </ButtonBase>
      </Grid>
    </>
  );
};

export default DrawerHeader;
