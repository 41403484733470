import { makeStyles } from "@material-ui/core/styles";
import { themeStyleSheet, themeColors } from "../../../constants";
const useStyles = makeStyles((theme) => ({
  container: {
    background: "white",
    padding: 0,
    margin: 0,
  },

  dateTag: {
    color: "rgba(0,0,0,0.75)",
    fontSize: "14px",
    backgroundColor: "#D3E8E8",
    padding: "5px 13px",
    height: "max-content",
    marginTop: "10px",
    borderRadius: "4px",
  },

  transactionAlertDesc: {
    marginTop: "20px",
    backgroundColor: "#FFF5C4",
    padding: "10px 10px",
    height: "max-content",
    alignItems: "center",
    borderWidth: 1,
    [`${theme.breakpoints.down("sm")}  `]: {
      width: "70%",
    },
    [`${theme.breakpoints.down("xs")}  `]: {
      width: "70%",
    },
    ["@media (max-device-width : 400px)"]: {
      width: "80%",
    },
    width: "fit-content",
    borderColor:  themeColors.appColor  ,
    borderRadius: 7,
  },
  transactionAlertDescText: {
    fontSize: 14,
    color: themeColors.datePickerText,
    [`${theme.breakpoints.down("sm")}  `]: {
      width: "calc(100% - 40px      )",
    },
   
    width: `calc(80% - 40px      )`,
    paddingLeft: "10px",
  },
  transactionIcon: {
    width: "20px",
    fontSize: 20,
    color: "rgba(0,0,0,0.75)",
  },

  transactionCont: {
    borderColor: props => props.type === "debit"? themeColors.amountDue : themeColors.appColor,
    borderWidth: 1,
    borderStyle: "solid",
    padding: "10px 10px",
    paddingBottom: "5px",
    borderRadius: 7,
    margin: "10px",
    [`${theme.breakpoints.down("sm")}  `]: {
      width: "70%",
    },
    ["@media (max-device-width : 400px)"]: {
 
      width: "80%",
    },

    [`${theme.breakpoints.up("lg")}  `]: {
      width: "400px",
      margin: "10px 50px",
    },
    [`${theme.breakpoints.up("xl")}  `]: {
      width: "500px",
      margin: "10px 100px",
    },

    width: "50%",
  },
  transactionAmountText: {
    color:  props => props.type === "debit"? themeColors.amountDue : themeColors.appColor  ,
    fontSize: "18px",
    lineHeight: "17px",
    fontWeight: "500",
  },
  transactionNoteCont: {
    marginTop: "5px",
  },
  transactionNoteText: {
    fontSize: "14px",
    color: "lightgray",
    [`${theme.breakpoints.down("sm")}  `]: {
      maxWidth: "70%",
    },
    maxWidth: "80%",
    textOverflow: "ellipsis",
    overflow: "hidden",
    height: "20px",
    whiteSpace: "nowrap",
  },
  transaction_image: {
    height: "200px",
    width: "100%",
    objectFit: "cover",
    margin: "8px 0px",
  },

  transactionTimeStampCont: {
    display: "flex",
    alignItems: "center",
  },
  transactionTimeStamp: {
    color: themeColors.transactionTimeStampText,
    fontSize: "12px",
  },
  transactionImage: {},
  cloudIcon: {
    marginLeft: "5px",
    fontSize: 15,
    color: "#4fc3f7",
  },
}));

export default useStyles;
