import { makeStyles, fade } from "@material-ui/core/styles";
import { themeStyleSheet, themeColors } from "../../../constants";
const useStyles = makeStyles((theme) => ({
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: "#e7e7e7",
    width: "100%",
  },
  searchIcon: {
    padding: theme.spacing(0, 1.2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    color: themeColors.searchIconColor,
  },
  filterIcon: {
    padding: theme.spacing(0, 1.2),
    height: "100%",
    position: "absolute",
    right: 0,
    top: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 5,
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1.5, 0, 1.5, 0),
    "&::placeholder": {
      fontWeight: "500",
    },
    color: "#737373",
    fontWeight: "500",
    // Vertical padding + font size from filterICon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    paddingRight: `calc(1em + ${theme.spacing(4)}px)`,
  },

  menuItemCont: {
    flexDirection: "row",
    width: "100%",
    marginRight: "20px",
  },
  menuItem: {
    width: "100%",
    minHeight: "0px",
  },
  menuItemIconCont: {
    alignItems: "center",
  },
  menuItemIcon: {
    fontSize: "20px",
    color: themeStyleSheet.appColor,
    right: "10px",
    position: "absolute",
  },
}));

export default useStyles;
