import { View } from "../reducer/layout";

export const getLoggedInUser = (state) => {
  return state.user;
};

export const getIsAuthenticated = (state) => {
  return state?.user?.isAuthenticated;
};

export const getAccounts = (state) => {
  return state?.accounts?.accounts;
};

export const getFilteredAccounts = (state) => {
  return state?.accounts?.filteredAccounts;
};

export const getTransactions = (state) => {
  return state?.transactions;
};

export const getViewStates = (state) => {
  return {
    CurrentTransaction: state.layout.views.includes(View.CurrentTransaction),
  };
};

export const getDrawerVisibility = (state) => {
  return state.layout.isDrawerOpen;
};
