import { useState, useEffect } from "react";

const useForm = (callback, validate) => {
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      callback(); // if no error invoke callback  
    }
    console.log(errors);
  }, [errors]);

  const handleSubmit = (event) => {
    if (event) event.preventDefault();

    setErrors(validate(values));
    setIsSubmitting(true);
  };

  const handleChange = (event) => {
    event.persist();

    if (event.target.type === "checkbox") {
      setValues((values) => ({
        ...values,
        [event.target.name]: event.target.checked,
      }));
    } else
      setValues((values) => ({
        ...values,
        [event.target.name]: event.target.value,
      }));
  };

  return {
    handleChange,
    handleSubmit,
    values,
    errors,
    setValues
  };
};

export default useForm;
