import React, { useState } from "react";
import { useDispatch ,useSelector} from "react-redux";

import {
  ListItem,
  ListItemText,
  Icon,
  ListItemIcon,
 
  Divider,
  List,
  Drawer,
 
} from "@material-ui/core";
import useStyles from "./styles";
 

import ProfileItem from "./components/ProfileItem";
import { useDrawerData } from "./customHooks/useDrawerData";
import { useDrawerRoutes } from "./customHooks/useDrawerRoutes";
import Loader from "../Loader";
import { getDrawerVisibility } from "../../../store/selectors";
import LayoutActions from "../../../store/actions/Layout";

export const DrawerLayout = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const isDrawerOpen = useSelector(getDrawerVisibility)

  const { user } = useDrawerData(setLoading);
  const { drawerRoutes } = useDrawerRoutes(setLoading);
  const [selectedRoute, setSelectedRoute] = useState();
  const container =
    window !== undefined ? () => window.document.body : undefined;

  const handleDrawerToggle = () => {
    dispatch (LayoutActions.closeDrawer())
  };

  const drawer = (
    <div>
      <ProfileItem toggleDrawer={handleDrawerToggle} user={user} />
      <Divider />
      <List>
        {drawerRoutes.map((item, index) => {
          if (item?.divider) return <Divider />;
          return (
            <ListItem
              button
              key={item.key}
              selected={selectedRoute === item.key}
              onClick={() => {
                setSelectedRoute(item.key);
                item.onClick();
                handleDrawerToggle();
              }}
            >
              <ListItemIcon>
                <Icon>{item.icon}</Icon>
              </ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItem>
          );
        })}
      </List>
    </div>
  );
  return (
    <>
      <Loader loading={loading} />
      {/* <Header
        title= {user?.business_name}
        toggleDrawer={handleDrawerToggle}
        open={mobileOpen}
      /> */}
      <nav className={classes.drawer} aria-label="Navigation Drawer">
        <Drawer
          container={container}
          variant="temporary"
          open={isDrawerOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {drawer}
        </Drawer>
      </nav>

      <main className={classes.content}>
        {/* <div className={classes.toolbar}></div> */}
        {props.children}
        
      </main>

      {/* <Hidden mdUp>
        <BottomTab />
      </Hidden> */}
    </>
  );
};

export default DrawerLayout;
