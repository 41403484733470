import { makeStyles } from "@material-ui/core/styles";
 
import { themeStyleSheet, themeColors } from "../../../constants";

const useStyles = makeStyles((theme) => ({
  mainCont: {
    width: "100%",
  },
  drawer: {
    
    padding: 0,
    margin: 0,
  },

  drawerPaper: {
    width: themeStyleSheet.drawerWidth,
    [`${theme.breakpoints.up("md")}  `]: {
      width: `400px`, 
    }, 
  
  },
  toolbar: {
    ...theme.mixins.toolbar,
  },

  content: {
    overflow: "hidden",
    [`${theme.breakpoints.down("sm")} and (orientation: landscape)`]: {
      maxHeight: `calc(100vh - 60px)`,// 60px magic no (minHeight for bottom tab for the device less then 600 width)
    },
    height:"100vh",

  },
}));

export default useStyles;
