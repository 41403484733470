import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Grid,
  TextField,
  Typography,
  Icon,
  ButtonBase,
  Button,
} from "@material-ui/core";

import useStyles from "./styles";
import DrawerHeader from "../../common/DrawerHeader";
import { getSampleTextForSMSAlerts } from "../../../utils";
import AccountsMiddleware from "../../../store/middleware/Accounts";
import Loader from "../../common/Loader";
import { strings } from "../../../i18n";

const languages = [
  {
    name: "English",
    iso2: "en",
  },
  {
    name: "Roman Urdu",
    iso2: "roman_ur",
  },
  {
    name: "اردو",
    iso2: "ur",
  },
  {
    name: "سنڌي",
    iso2: "si",
  },
  {
    name: "پن٘جابی",
    iso2: "pu",
  },
  {
    name: "پشتو",
    iso2: "pa",
  },
];

const SMSLangugaeSelection = ({
  history,
  account,
  onClose,
  preferred_language,
  onSubmit,
}) => {
  const classes = useStyles();
  const [selectedLang, setSelectedLang] = useState(preferred_language);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const updateLanguage = () => {
    if (account) {
      let id = account.id;
      let data = { preferred_language: selectedLang };
      setLoading(true);
      dispatch(AccountsMiddleware.update(id, data)).then((res) => {
        onSubmit(selectedLang);
        setLoading(false);
      });
    } else {
      onSubmit(selectedLang);
    }
  };
  return (
    <>
      <Loader loading={loading} />
      <Grid className={classes.mainCont} justify="center">
        <DrawerHeader
          title={"SMS Alert Language"}
          leftIcon={"arrow_backward"}
          leftIconFunc={onClose}
        />

        <Grid
          container
          direction="row"
          item
          justify="center"
          className={classes.root}
        >
          <Grid container item justify="center">
            <Typography  className={classes.transactionSMSText}>
              Transactional SMS Sample
            </Typography>
          </Grid>

          <Grid container item className={classes.sampleSMSCont}>
            <Typography align="center" >{getSampleTextForSMSAlerts(selectedLang)}</Typography>
          </Grid>
          <Grid
            container
            item
            justify="center"
            className={classes.chooseLangCont}
          >
            <Typography align="right" className={classes.chooseLangText}>
              Choose your language
            </Typography>
          </Grid>

          {languages.map((item, index) => (
            <ButtonBase
              focusRipple
              onClick={() => setSelectedLang(item.iso2)}
              className={classes.languageItemCont}
            >
              <Grid container item justify="space-between">
                <Typography className={classes.langText}>
                  {item.name}
                </Typography>
                {selectedLang === item.iso2 ? (
                  <Icon className={classes.langCheckIcon}>check_circle</Icon>
                ) : null}
              </Grid>
            </ButtonBase>
          ))}
          <Button
            variant="contained"
            color="primary"
            className={classes.confirmButton}
            onClick={() => updateLanguage()}
          >
            {strings("CONFIRM_LANGUAGE")}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default SMSLangugaeSelection;
