import React, { useState } from "react";
import { useDispatch } from "react-redux";

import {
  Grid,
  Typography,
  useTheme,
  Avatar,
  Divider,
  ButtonBase,
} from "@material-ui/core";

import useStyles from "./styles";
import { strings } from "../../../i18n";
import { formattedDate, formatNum } from "../../../utils";
export const Account = ({ history, item, isLast, onClick }) => {
  const classes = useStyles({ current_balance: item.current_balance });
  const dispatch = useDispatch();

  return (
    <>
      <ButtonBase
        focusRipple
        className={classes.root}
        onClick={() => onClick(item.id )}
      >
        <Grid
          container
          item
          xs={12}
          direction="row"
          justify="flex-start"
          alignItems="center"
          className={classes.userRowCont}
        >
          <Grid item>
            <Avatar className={classes.userAvatar} src={item.img_url} />
          </Grid>
          <Grid container item direction="column" justify="flex-start" xs>
            <Typography align="left" className={classes.customerNameText}>
              {item.name}
            </Typography>
            <Typography align="left" className={classes.label}>
              {item.last_transaction
                ? item.last_transaction.transaction_type === "debit"
                  ? `${strings("TOOK_LOAN")} ${formatNum(
                      item.last_transaction.amount
                    )} ${strings("RUPEES")}`
                  : `${strings("PAID")} ${formatNum(
                      item.last_transaction.amount
                    )} ${strings("RUPEES")}`
                : `${strings("ADDED_ON")} ${formattedDate(
                    item.creation_timestamp
                  )}`}
            </Typography>
          </Grid>
          <Grid container item direction="column" xs>
            <Typography
              align="right"
              className={[classes.label, classes.amountTextcolor]}
            >
              {item.current_balance > 0
                ? strings("PAY")
                : item.current_balance === 0
                ? strings("CLEAR")
                : strings("DUE")}
            </Typography>
            <Typography
              align="right"
              className={[classes.customerAmountText, classes.amountTextcolor]}
            >
              Rs {formatNum(Math.abs(item.current_balance))}
            </Typography>
          </Grid>
        </Grid>
      </ButtonBase>
      {!isLast && <Divider variant="fullWidth" className={classes.divider} />}
    </>
  );
};

export default Account;
