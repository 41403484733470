import { strings } from "../../../../i18n";
import { phoneNumPatt } from "../../../../constants";

const isRequired = (value) => !value || value?.length === 0;
const validate = (values) => {
  const { name, phone, creditLimit } = values;
  let errors = {};
  let onlyDigitReg = /^\d+$/;

  if (isRequired(name)) errors.name = strings("FIELD_REQUIRED");
 

  
  if (phone?.length && phone.match(phoneNumPatt) === null) {
    errors.phone = strings("INVALID_MOBILE_NO_ERROR");
  }
  if (phone?.length && phone.match(phoneNumPatt) === null) {
    errors.phone = strings("INVALID_MOBILE_NO_ERROR");
  }
  if (parseInt(creditLimit) === 0) {
    errors.creditLimit = strings("CREDIT_LIMTI_CANT_BE_ZERO");
  }

  if (creditLimit?.length && creditLimit.match(onlyDigitReg) === null) {
    errors.creditLimit = "Invalid Credit Limit";
  }

  return errors;
};

export default validate;
