import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Grid,
  TextField,
  Typography,
  Icon,
  IconButton,
  InputAdornment,
  CircularProgress,
  Collapse,
} from "@material-ui/core";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import useStyles from "./styles";
import clsx from "clsx";
import Loader from "../../common/Loader";
import Calculator from "../../common/Calculator";
import {
  formatNum,
  getTransactionalSMSText,
  sendSMS,
  getRandomInt,
  storeImageInFirebase,
} from "../../../utils";
import AccountHeader from "../../common/AccountHeader";

import TransactionsMiddleware from "../../../store/middleware/Transactions";
import { strings } from "../../../i18n";
import { transaction } from "../../../firebase/schema/transactions";
import { getLoggedInUser } from "../../../store/selectors";
import Transaction from "../../common/Transaction";
import useFocus from "../../../hooks/useFocus";
import useCheckMobileScreen from "../../../hooks/useCheckMobileScreen";
export const CreateTransaction = ({
  history,
  account,
  type,
  onModalClose,
  selectedTransaction,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const loggedInUser = useSelector(getLoggedInUser);

  const [loading, setLoading] = useState(false);
  const [inputRef, setInputFocus] = useFocus()
  const [calcText, setCalcText] = useState("");
  const [result, setResult] = useState("");
  const [keyMap, setKeyMap] = useState();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [note, setNote] = useState("");

  const [showCalculator, setShowCalculator] = useState(true);
  const [imgPath, setImgPath] = useState("");

  const [imgUploading, setImageUploading] = useState(false);
  const isMobile = useCheckMobileScreen();

  useEffect(() => {
    if (selectedTransaction) {
      let amount = selectedTransaction?.amount || "";
      let note = selectedTransaction?.note || "";
      let chosenDate =
        selectedTransaction?.transaction_timestamp?.toDate() || new Date();
      let calculatorText = selectedTransaction?.amount;
      setCalcText(calculatorText);
      setResult(amount);
      setNote(note);
      setImgPath(selectedTransaction?.transaction_image || "");
      setSelectedDate(chosenDate);
    }
  }, [selectedTransaction]);

  const updateTransaction = async () => {
    setLoading(true);
    let transaction_id = selectedTransaction?.id;
    let data = {
      amount: result.toString(),
      note,
      transaction_timestamp: selectedDate,
      transaction_image: imgPath,
    };

    let transactionAmtDiff =
      Number(data.amount) - Number(selectedTransaction?.amount);

    let updatedCustomerData = {
      current_balance:
        selectedTransaction?.transaction_type === "debit"
          ? account.current_balance - Number(transactionAmtDiff)
          : account.current_balance + Number(transactionAmtDiff),
      amount_of_credits:
        selectedTransaction?.transaction_type === "debit"
          ? account.amount_of_credits
          : account.amount_of_credits - Number(transactionAmtDiff),
      amount_of_debits:
        selectedTransaction?.transaction_type === "debit"
          ? account.amount_of_debits - Number(transactionAmtDiff)
          : account.amount_of_debits,
    };
    if (account.mobile_no && account.is_send_transactional_sms_active) {
      try {
        let msg = "";
        let transactionMsg = getTransactionalSMSText(
          account.preferred_language,
          data.transaction_type,
          loggedInUser.business_name || loggedInUser.name,
          data.amount,
          updatedCustomerData.current_balance,
          loggedInUser?.mobile_no,
          data.transaction_timestamp
        );
        msg = `Transaction Edit: ${transactionMsg}`;
        sendSMS(account.mobile_no, msg);
      } catch (err) {
        //  console.log("error while sending transaction msg! ", err);
      }
    }

    dispatch(TransactionsMiddleware.update(transaction_id, data))
      .then((res) => {
        setLoading(false);
        onModalClose();
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  const createTransaction = async () => {
    setLoading(true);
    let data = {
      ...transaction,
      amount: result.toString(),
      note,
      creation_timestamp: new Date(),
      transaction_timestamp: selectedDate,
      user_id: account && account.user_id,
      customer_id: account?.id,
      customer_name: account?.name,
      customer_mobile_no: account?.mobile_no,
      transaction_type: type === "Give" ? "debit" : "credit",
      transaction_image: imgPath,
    };
    // console.log("Transaction data  :==== ", data);

    if (account.mobile_no && account.is_send_transactional_sms_active) {
      try {
        let msg = "";
        let updatedCustomerData = {
          current_balance:
            data.transaction_type === "debit"
              ? account.current_balance - Number(data.amount)
              : account.current_balance + Number(data.amount),
        };

        msg = `${getTransactionalSMSText(
          account.preferred_language,
          data.transaction_type,
          loggedInUser.business_name || loggedInUser.name,
          data.amount,
          updatedCustomerData.current_balance,
          loggedInUser?.mobile_no,
          data.transaction_timestamp
        )}\nDetails: https://tinyurl.com/y7dlfa4z/?id=${account?.id}`;
        sendSMS(account.mobile_no, msg);
        let followUpMsg = "";
        if (account?.no_of_credits === 0 && account?.no_of_debits === 0) {
          let randomNo = getRandomInt(2);
          if (randomNo === 0) {
            followUpMsg =
              "Creditbook ki taraf se yeh sms ap ko kyun musool hua hai? Janane k lie is link par click karein https://digitalkhata.creditbook.pk";
          } else if (randomNo === 1) {
            followUpMsg =
              "CreditBook se apne ap bhi apne karobaar ka hisaab kitaab digital kar sakte hein! CreditBook Application apko SMS payment reminders, data backup aur unlimited customer accounts jesi bohat si features ki sahulat deta hai! Aj hi CreditBook playstore se download karein https://bit.ly/35k0IS1";
          }
          sendSMS(account.mobile_no, followUpMsg);
        }
      } catch (err) {
        // console.log("error while sending transaction msg! ", err);
      }
    }

    dispatch(TransactionsMiddleware.createTransaction(data))
      .then((res) => {
        setLoading(false);
        onModalClose();
      })
      .catch((err) => {
        // Print Error
        setLoading(false);
      });
  };

  // Customer ID and Account ID required
  const handleKeyDown = (e) => {
    if (!showCalculator) return;
    let button;
    //  console.log(e);
    if (e.ctrlKey || e.keyCode === 67) {
      return;
    }
    let key = (e.shiftKey ? "shift+" : "") + e.keyCode || e.which;
    console.log(key)
    let key_dict = {
      96: 48,
      97: 49,
      98: 50,
      99: 51,
      100: 52,
      101: 53,
      102: 54,
      103: 55,
      104: 56,
      105: 57,
      106: "shift+56",  // * multiply
      107: "shift+187", // + add
      109: "shift+189", // - subtract
      110: 190, //  . decimal point

    }
    if (key_dict[key])
      key = key_dict[key]
    if ((button = keyMap[key])) {
      button.click();
      e.stopPropagation();
      e.preventDefault();
    }
  };

  const handleImageChange = async (e) => {
    const image = e.target.files[0];

    if (!image) return;
    setImageUploading(true);
    storeImageInFirebase(
      "_transactions",
      new Date().getTime().toString(),
      image
    )
      .then((url) => {
        setImageUploading(false);
        setImgPath(url);
      })
      .catch((err) => {
        setImageUploading(false);
      });
  };
  const onClick = (value) => {
    const currentVal = calcText;
    const lastLetter = currentVal.slice(-1);

    switch (value) {
      case "c":
        setCalcText(currentVal.slice(0, -1));

        try {
          let amount = eval(currentVal.slice(0, -1));
          setResult(amount);
        } catch (e) { }

        break;

      case "=":
        try {
          const output = eval(currentVal).toString();
          setResult(output);
        } catch (e) { }
        break;
      case "+":
      case "-":
      case "*":
      case "/":
        if (result?.toString().length > 12) return;
        if (calcText.length > 35) return;

        if (!currentVal) {
          break;
        }
        if (
          (lastLetter === "*" && value === "-") ||
          (lastLetter === "/" && value === "-")
        ) {
          setCalcText(currentVal + value);
          break;
        }

        if (
          lastLetter === "+" ||
          lastLetter === "-" ||
          lastLetter === "*" ||
          lastLetter === "/"
        )
          setCalcText(currentVal);
        else setCalcText(currentVal + value);
        break;
      case ".":
        if (result?.toString().length > 12) return;
        if (calcText.length > 35) return;
        if (lastLetter != ".") {
          setCalcText(currentVal + value);
        }
        break;
      default:
        if (result?.toString().length > 12) return;
        if (calcText.length > 35) return;
        setCalcText(currentVal + value);
        try {
          let amount = eval(currentVal + value);
          setResult(amount);
        } catch (e) { }

        break;
    }
  };
  const changeCalulatorVisibility = (value) => {

    setTimeout(() => {
      setShowCalculator(value);
      value && setInputFocus()
    }, 200);

  };
  const getHeaderSubTitle = (account) => {
    let subTitle = "";

    if (account) {
      if (account.current_balance > 0)
        subTitle = `${strings("PAY")} Rs ${formatNum(
          Math.abs(account.current_balance)
        )}`;
      else if (account.current_balance < 0)
        subTitle = `${strings("DUE")} Rs ${formatNum(
          Math.abs(account.current_balance)
        )}`;
      else subTitle = strings("CLEAR");
    }
    return subTitle;
  };

  return (
    <>
      <Grid
        direction="column"
        container
        xs={12}
        onKeyDown={handleKeyDown}
        className={classes.mainCont}
      >
        <AccountHeader
          title={account && account.name}
          leftIcon="arrow_back"
          subTitle={getHeaderSubTitle(account)}
          titleImgSrc={account && account.titleImgSrc}
          titleImgFunc={() => console.log("Title Image CLicked")}
          leftIconFunc={onModalClose}
          showAvatar={true}
        />
        <Grid container item direction="row" justify="center">

          <Grid
            container
            item
            direction="row"
            justify="center"
            className={classes.calculatorTextCont}
          >
            <Typography className={clsx(classes.calculatorText)}>
              Rs.
            </Typography>
            <input
              ref={inputRef}
              className={clsx(classes.inputHidden)}
              autoFocus
              defaultValue="will focus"
            />
            <Typography
              className={clsx(classes.calculatorText, classes.bold)}
              paragraph
            >
              {calcText}
            </Typography>
          </Grid>
          <div className={classes.inputBottomBorder}> </div>
        </Grid>
        <Typography align="center" className={clsx(classes.totalAmtText)}>
          Total: {result && formatNum(result)}
        </Typography>

        <Grid container item justify="center">
          <div className={classes.datePickerMainCont}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <DatePicker
                inputProps={{
                  style: {
                    textAlign: "center",
                    color: "rgba(0,0,0,0.65)",
                    cursor: "pointer",
                    margin: "5px 10px",
                  },
                }}
                format="MMM DD, YYYY"
                value={selectedDate}
                onChange={(date) => setSelectedDate(date)}
                textFieldStyle={{ width: "100%" }}
                InputProps={{
                  disableUnderline: true,
                  startAdornment: (
                    <InputAdornment position={"end"}>
                      <Icon className={classes.datePickerCalenderIcon}>
                        event
                      </Icon>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position={"end"}>
                      <Icon className={classes.datePcikerDropDownICon}>
                        arrow_drop_down
                      </Icon>
                    </InputAdornment>
                  ),
                }}
                maxDate={new Date()}
              />
            </MuiPickersUtilsProvider>
          </div>
        </Grid>

        <Grid className={classes.calculatorCont}>
          <Grid
            className={classes.noteMainCont}
            container
            item
            alignItems="center"
          >
            <Grid
              container
              item
              alignItems="center"
              className={classes.noteCont}
            >
              <TextField
                type="text"
                fullWidth
                multiline
                rowsMax={2}
                className={classes.noteTextInput}
                value={note}
                InputProps={{ disableUnderline: true }}
                placeholder="Write your note here"
                onFocus={() => changeCalulatorVisibility(false)}
                onBlur={() => changeCalulatorVisibility(true)}
                onChange={(e) => setNote(e.target.value)}
              />
              <input
                accept="image/*"
                className={classes.inputFile}
                id="contained-button-file"
                type="file"
                disabled={imgUploading}
                onChange={handleImageChange}
              />
              <label htmlFor="contained-button-file">
                <IconButton
                  className={classes.cameraIconCont}
                  component="span"
                ></IconButton>

                <IconButton className={classes.cameraIconCont} component="span">
                  {imgUploading ? (
                    <CircularProgress
                      size={25}
                      className={classes.cameraIcon}
                    />
                  ) : imgPath ? (
                    <img className={classes.imgTransaction} src={imgPath}></img>
                  ) : (
                    <Icon className={classes.cameraIcon}>camera_alt</Icon>
                  )}
                </IconButton>
              </label>
            </Grid>

            <IconButton
              onClick={
                selectedTransaction ? updateTransaction : createTransaction
              }
              disabled={loading || imgUploading || !result}
              classes={{
                root: classes.saveTransactionBtn,
                disabled: classes.disabled,
              }}
            >
              {loading ? (
                <CircularProgress size={20} style={{ color: "white" }} />
              ) : (
                <Icon className={classes.saveTransactionArrowIcon}>
                  {type === "Give" ? "arrow_upward" : "arrow_downward"}
                </Icon>
              )}
            </IconButton>
          </Grid>
          <Collapse in={showCalculator || !isMobile}>
            <Calculator
              onClick={onClick}
              onLoad={(keyMap) => {
                setKeyMap(keyMap);
              }}
            />
          </Collapse>
        </Grid>
      </Grid>
    </>
  );
};

export default CreateTransaction;
