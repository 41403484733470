import { makeStyles } from "@material-ui/core/styles";
import { themeStyleSheet, themeColors } from "../../../constants";
const useStyles = makeStyles((theme) => ({
  root: {
    ["@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) "]: {
      maxWidth: "50%",
    },
  },
  imgCont: {
    objectFit: "contain",
    justifyContent: "center",
    alignItems: "center",
    padding: "20px 0px",
    height: "200px",
  },

  chooseLangText: {
    fontSize: 22,
    color: themeColors.appColor,
    marginTop: "30px",
  },
  languageItemCont: {
    margin: "10px 0px",
    display: "flex",
    width: "100%",
    padding: "0px 20px",
  },
  langText: {
    fontSize: 18,
    color: themeColors.langText,
    textAlign: "left",
  },
  langCheckIcon: {
    fontSize: 25,
    color: themeColors.langCheckIcon,
  },
}));

export default useStyles;
