import React, { useEffect, useState } from "react";
import { ButtonBase, Grid, Icon, Typography } from "@material-ui/core";

import useStyles from "./styles";
import OnBoardingFooter from "../../common/OnBoardingFooter";
import LanguageUpdated from "../../../assets/language-updated.png";
import { changelocale } from "../../../i18n";
const title = {
  en: "Choose your language",
  ur: "اپنی زبان چنے",
  pu: "اپنی زبان چنو",
  pa: "خپله ژبه منتخب کړئ",
  si: "پنھجي زبان چونڊيو",
  roman_ur: "Apni zabaan chunne",
};

const languages = [
  {
    name: "English",
    iso2: "en",
  },
  {
    name: "Roman Urdu",
    iso2: "roman_ur",
  },
  {
    name: "اردو",
    iso2: "ur",
  },
  {
    name: "سنڌي",
    iso2: "si",
  },
  {
    name: "پن٘جابی",
    iso2: "pu",
  },
  {
    name: "پشتو",
    iso2: "pa",
  },
];
export const Welcome = ({ history }) => {
  const classes = useStyles();
  const [selectedLang, setSelectedLang] = useState("en");

  const getText = (selectedLang) => {
    switch (selectedLang) {
      case "en":
        return "Next";
      case "ur":
        return "آگے";
      case "roman_ur":
        return "Agaey";
      case "si":
        return "اڳيان";
      case "pa":
        return "مخکښې";
      case "pu":
        return "آگے";
      default:
        return "Next";
    }
  };

  useEffect(() => {
    const lang = localStorage.getItem("language");
 
    if (lang) {
      setSelectedLang(lang);
    }
  }, []);
  const selectLanguage = async (lang) => {
    let selectedLangClone = selectedLang;

    changelocale(
      selectedLangClone,
      selectedLangClone === "en" || selectedLangClone === "roman_ur"
        ? false
        : true
    );

    localStorage.setItem("language", selectedLangClone);
    history.push("/login");
  };

  return (
    <>
      <Grid style={{ minHeight: "90vh" }} container justify="center">
        <Grid
          container
          direction="column"
          item
          justify="center"
          xs={12}
          md={8}
          lg={4}
          xl={3}
          className={classes.root}
        >
          <Grid container item justify="center">
            <img src={LanguageUpdated} className={classes.imgCont}></img>
          </Grid>
          <Grid
            container
            item
            justify="center"
            className={classes.chooseLangCont}
          >
            <Typography align="right" className={classes.chooseLangText}>
              {title[selectedLang]}
            </Typography>
          </Grid>

          {languages.map((item, index) => (
            <ButtonBase focusRipple onClick={() => setSelectedLang(item.iso2)}>
              <Grid
                container
                item
                direction="row"
                justify="space-between"
                className={classes.languageItemCont}
              >
                <Typography className={classes.langText}>
                  {item.name}
                </Typography>
                {selectedLang === item.iso2 ? (
                  <Icon className={classes.langCheckIcon}>check_circle</Icon>
                ) : null}
              </Grid>
            </ButtonBase>
          ))}
        </Grid>
      </Grid>
      <OnBoardingFooter
        rightText={getText(selectedLang)}
        rightFunc={selectLanguage}
      />
    </>
  );
};

export default Welcome;
