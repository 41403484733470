import { makeStyles } from "@material-ui/core/styles";
import { themeStyleSheet, themeColors } from "../../../constants";
const useStyles = makeStyles({
  otpInput: {
    width: "2.5rem !important",
    height: "2.5rem",
    margin: "20px 3px",
    fontSize: "1.5rem",
    borderRadius: "4px",
    border: "1px solid rgba(0,0,0,0.3)",
    color: "#000",
  },

  verificationCodeHeading: {
    fontSize: 22,
    textAlign: "center",
    color: themeColors.appColor,
    fontWeight: 700,
  },
  verificationTextCont: {
    margin: "20px 0",
    maxWidth: "280px",
    textAlign: "center",
  },
});

export default useStyles;
